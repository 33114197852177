import { FC, useState } from "react"
import { Stack, Tabs, Tab } from "@mui/material"
import { ZyteContainer } from "./ZyteContainer"
import { Container as ContentAcquisitionContainer } from "./CustomMade/Container"
import { a11yProps } from "../../shared/config"

interface TabOptionValue {
  title: string
  component: JSX.Element
}

export const ProxyContainer: FC = () => {
  const [tab, setTab] = useState("zyte")

  const tabOptions: { [key: string]: TabOptionValue } = {
    zyte: {
      title: "Zyte Proxies",
      component: <ZyteContainer />,
    },
    bombus: {
      title: "Bombus Proxies",
      component: <ContentAcquisitionContainer />,
    },
  }

  return (
    <Stack spacing={2}>
      <Tabs aria-label="proxies tabs" value={tab}>
        {Object.keys(tabOptions).map((index) => (
          <Tab
            key={index}
            label={tabOptions[index].title}
            onClick={() => setTab(index)}
            value={index}
            {...a11yProps(index)}
          />
        ))}
      </Tabs>
      {tabOptions[tab].component}
    </Stack>
  )
}
