import { FC } from "react"
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  Typography,
  TablePagination,
  TableSortLabel,
  TableCell,
} from "@mui/material"
import { useLocation } from "react-router-dom"
import { TableHeaderCell } from "../components/shared/DataTable/TableWithHeader"
import { Layout } from "../components/shared/Layout"
import { urlParamsToBriefingQueryObject } from "../api/briefings"
import useSetUrlParamState from "../components/shared/UseSetUrlParamState"
import { OpportunityTableRow } from "../components/Opportunities/OpportunityTableRow"
import { OpportunityTableSearchForm } from "../components/Opportunities/OpportunityTableSearchForm"
import { useFetchOpportunities } from "@src/api/opportunities"
import Loading from "@src/components/shared/Loading"

const tableHeaders = [
  { label: "Title", sortId: "title" },
  { label: "Description", sortId: "description" },
  { label: "Actionability", sortId: "actionability" },
  { label: "NAICS Code", sortId: "naics" },
  { label: "Actionable Date", sortId: "actionable_date" },
  { label: "Key Speaker", sortId: "speaker" },
  { label: "Meeting Published At", sortId: "date" },
  { label: "Channel", sortId: "channel" },
  { label: "Location", sortId: "location" },
  { label: "Identified On", sortId: "identified_date" },
]

export const OpportunitiesPage: FC = () => {
  const params = new URLSearchParams(useLocation().search)
  const { data, isLoading } = useFetchOpportunities(params)

  const urlParamState = urlParamsToBriefingQueryObject(params)
  const setUrlParamState = useSetUrlParamState<typeof urlParamState>()
  const sort = urlParamState.sort
  const order = urlParamState.order

  return (
    <Layout title="Opportunities">
      <OpportunityTableSearchForm />
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {tableHeaders.map(({ label, sortId }) => (
                <TableHeaderCell sortDirection={sort === sortId && (order as "asc" | "desc")}>
                  <TableSortLabel
                    active={sort === sortId}
                    direction={sort === sortId ? (order as "asc" | "desc") : undefined}
                    onClick={() =>
                      setUrlParamState({
                        ...urlParamState,
                        sort: sortId || "",
                        order: order === "asc" ? "desc" : "asc",
                        page: 0,
                      })
                    }
                  >
                    <Typography variant="h4">{label}</Typography>
                  </TableSortLabel>
                </TableHeaderCell>
              ))}
              <TableHeaderCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading && (
              <TableRow>
                <TableCell colSpan={tableHeaders.length + 1} height="160px" sx={{ position: "relative" }}>
                  <Loading useCloverleafIcon />
                </TableCell>
              </TableRow>
            )}
            {data?.opportunities.map((opportunity) => (
              <OpportunityTableRow key={opportunity.id} opportunity={opportunity} />
            ))}
            {data?.opportunities.length === 0 && (
              <TableRow sx={{ backgroundColor: "primary.dark" }}>
                <TableCell colSpan={tableHeaders.length + 1}>
                  <Typography variant="h4" color="neutral.light" textAlign="center" paddingY={2}>
                    No Opportunities Found
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[]}
        count={data?.total ?? -1}
        rowsPerPage={25}
        page={urlParamState.page}
        onPageChange={(_event, page) => setUrlParamState({ ...urlParamState, page })}
      />
    </Layout>
  )
}
