import { FC } from "react"
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  Typography,
  TablePagination,
  TableSortLabel,
} from "@mui/material"
import { useLocation } from "react-router-dom"
import { TableHeaderCell } from "../components/shared/DataTable/TableWithHeader"
import { Layout } from "../components/shared/Layout"
import { getBriefings, urlParamsToBriefingQueryObject } from "../api/briefings"
import useSetUrlParamState from "../components/shared/UseSetUrlParamState"
import { BriefingTableRow } from "../components/Briefings/BriefingTableRow"
import { BriefingTableSearchForm } from "../components/Briefings/BriefingTableSearchForm"

const tableHeaders = [
  { label: "Meeting", sortId: "meeting" },
  { label: "Date", sortId: "date" },
  { label: "Channel", sortId: "channel" },
  { label: "Location", sortId: "location" },
  { label: "Type", sortId: "type" },
  { label: "Owner", sortId: "owner" },
  { label: "Created At", sortId: "created" },
]

export const BriefingsPage: FC = () => {
  const params = new URLSearchParams(useLocation().search)
  const { data } = getBriefings(params)

  const urlParamState = urlParamsToBriefingQueryObject(params)
  const setUrlParamState = useSetUrlParamState<typeof urlParamState>()
  const sort = urlParamState.sort
  const order = urlParamState.order

  return (
    <Layout title="Briefings">
      <BriefingTableSearchForm />
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {tableHeaders.map(({ label, sortId }) => (
                <TableHeaderCell sortDirection={sort === sortId && (order as "asc" | "desc")}>
                  <TableSortLabel
                    active={sort === sortId}
                    direction={sort === sortId ? (order as "asc" | "desc") : undefined}
                    onClick={() =>
                      setUrlParamState({
                        ...urlParamState,
                        sort: sortId || "",
                        order: order === "asc" ? "desc" : "asc",
                        page: 0,
                      })
                    }
                  >
                    <Typography variant="h4">{label}</Typography>
                  </TableSortLabel>
                </TableHeaderCell>
              ))}
              <TableHeaderCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.briefings.map((briefing) => <BriefingTableRow key={briefing.id} briefing={briefing} />)}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[]}
        count={data?.total ?? -1}
        rowsPerPage={25}
        page={urlParamState.page}
        onPageChange={(_event, page) => setUrlParamState({ ...urlParamState, page })}
      />
    </Layout>
  )
}
