import { useMutation, useQuery, useQueryClient } from "react-query"
import { useAuthAxios } from "../axiosInstance"
import { useSmartUpdate } from "../api"
import { Industry } from "@interfaces/industry"

const prefix = "/admin/industries"

export interface FetchedIndustryCampaign {
  id: number
  name: string
  discarded_at: string
}

export interface FetchedIndustryRecommendedCampaign {
  id: number
  use_case: string
  campaign: FetchedIndustryCampaign
}

export interface FetchedIndustryBookmarkFolder {
  id: number
  title: string
  visible: boolean
}

export interface FetchedIndustryAssociatedUser {
  id: number
  email: string
}
export interface FetchedIndustry {
  id: number
  name: string
  discarded_at: string
  recommended_campaigns: FetchedIndustryRecommendedCampaign[]
  campaigns: FetchedIndustryCampaign[]
  bookmarks: FetchedIndustryBookmarkFolder[]
  use_cases: string[]
  associated_user: FetchedIndustryAssociatedUser
}

export const useFetchIndustries = () => {
  const axiosInstance = useAuthAxios()

  return useQuery(["industries"], async () => {
    const response = await (await axiosInstance).get<FetchedIndustry[]>(prefix)

    return response.data
  })
}

export const useCreateIndustry = (onSuccess?: () => void) => {
  const axiosInstance = useAuthAxios()
  const smartUpdate = useSmartUpdate(useQueryClient())

  return useMutation(
    async (params: { name: string }) => {
      return (await axiosInstance).post<Industry>(prefix, params)
    },
    {
      onSuccess: (response) => {
        smartUpdate(response)
        if (onSuccess) {
          onSuccess()
        }
      },
    },
  )
}

export const useRestoreIndustry = (onSuccess?: () => void) => {
  const axiosInstance = useAuthAxios()
  const smartUpdate = useSmartUpdate(useQueryClient())

  return useMutation(
    async (id: number) => {
      return (await axiosInstance).put<Industry>(`${prefix}/${id}`, { undiscard: true })
    },
    {
      onSuccess: (response) => {
        smartUpdate(response)
        if (onSuccess) {
          onSuccess()
        }
      },
    },
  )
}

export const useUpdateIndustry = (onSuccess?: (msg: string) => void) => {
  const axiosInstance = useAuthAxios()
  const queryClient = useQueryClient()

  return useMutation(
    async (params: { id: number; user_id: number }) => {
      return (await axiosInstance).put<Industry>(`${prefix}/${params.id}`, { user_id: params.user_id })
    },
    {
      onSuccess: () => {
        if (onSuccess) {
          onSuccess("Industry Updated Successfully")
          queryClient.invalidateQueries("industries")
        }
      },
    },
  )
}

export const useDeleteIndustry = (onSuccess?: () => void) => {
  const axiosInstance = useAuthAxios()
  const smartUpdate = useSmartUpdate(useQueryClient())

  return useMutation(
    async (id: number) => {
      return (await axiosInstance).delete(`${prefix}/${id}`)
    },
    {
      onSuccess: (response) => {
        smartUpdate(response)
        if (onSuccess) {
          onSuccess()
        }
      },
    },
  )
}

export const useAddRecommendedCampaign = () => {
  const axiosInstance = useAuthAxios()
  const queryClient = useQueryClient()

  return useMutation(
    async (params: any) => {
      return (await axiosInstance).post(`/admin/industries/${params.industry_id}/add_recommended_campaign`, {
        campaign_id: params.campaign_id,
        use_case: params.use_case,
      })
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("industries")
      },
    },
  )
}

export const useRemoveRecommendedCampaign = () => {
  const axiosInstance = useAuthAxios()
  const queryClient = useQueryClient()

  return useMutation(
    async (params: any) => {
      return (await axiosInstance).delete(
        `/admin/industries/${params.industryId}/remove_recommended_campaign?recommended_campaign_id=${params.recommendedCampaignId}`,
      )
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("industries")
      },
    },
  )
}
