import { useQuery } from "react-query"
import { useAuthAxios } from "../../axiosInstance"
import { Video } from "@src/interfaces/content-acquisition/responseObjectInterfaces"
import { QueryParamsBuilder } from "@src/interfaces/content-acquisition/queryParamsBuilder"
import { CommonUrlParams } from "@src/interfaces/content-acquisition/requestParams"

const resourcePath = "/content-acquisition/videos"

export const useFetchScheduledVideos = (params?: CommonUrlParams) => {
  const axiosInstance = useAuthAxios()
  const paramBuilder = new QueryParamsBuilder()
  const queryParams = paramBuilder.build(params)
  const uri = `${resourcePath}${queryParams}`

  return useQuery(paramBuilder.toQueryKey("content-acquisition-videos"), async () => {
    const response = await (await axiosInstance).get<Video[]>(uri)

    return response.data
  })
}
