import { FC, useMemo, useState } from "react"
import { Typography, Autocomplete, TextField, TextFieldProps, SxProps, Theme } from "@mui/material"
import { useFetchOrganizations as fetchNonAdminOrganizations } from "@api/organizations"
import { useFetchOrganizations as fetchAdminOrganizations } from "@api/admin/organizations"
import { BasicOrgType } from "../../interface"
import { FilterParams } from "../../../../Home/interface"
import { styledGroupTitles } from "../../styles"
import { sortChannelByState } from "../../helpers"
import { useFetchGeographies } from "../../../../../api/geographies"
import { useLocation, useParams } from "react-router-dom"
import { organizationsFetchContext, useFetchOrganizationsMap } from "@src/functions/organizations"

export const getFilteredOptions = (filterParams: FilterParams, organizations: BasicOrgType[]): BasicOrgType[] => {
  return useMemo(() => {
    const newOpts = organizations.filter((org) => {
      if (org?.state === null) {
        return false
      }
      if (!filterParams["states"].includes(org?.state?.id || -1) && filterParams["states"].length !== 0) {
        return false
      } else if (
        filterParams["channel_types"] &&
        filterParams["channel_types"].length !== 0 &&
        !filterParams["channel_types"].includes(org?.type?.id || -1)
      ) {
        return false
      } else if (filterParams["cities"].length !== 0 && filterParams["counties"].length !== 0) {
        if (
          !filterParams["cities"].includes(org?.city?.id || -1) &&
          !filterParams["counties"].includes(org?.county?.id || -1)
        ) {
          return false
        }
      } else if (filterParams["cities"].length !== 0 && !filterParams["cities"].includes(org?.city?.id || -1)) {
        return false
      } else if (filterParams["counties"].length !== 0 && !filterParams["counties"].includes(org?.county?.id || -1)) {
        return false
      }

      return true
    })

    return newOpts
  }, [filterParams, organizations])
}

export const ChannelSelectorMultiple: FC<{
  options: BasicOrgType[]
  filterParams: FilterParams
  setFilterParams: (filterParams: FilterParams) => void
  textFieldProps?: TextFieldProps
  sx?: SxProps<Theme>
  size?: "medium" | "small"
}> = ({ options, filterParams, setFilterParams, textFieldProps, sx, size }) => {
  const { userOrganizationId } = useParams<{ userOrganizationId: string }>()
  const { data: geographiesData } = useFetchGeographies()
  const [open, setIsOpen] = useState(false)
  const { data: organizationsData } = organizationsFetchContext(userOrganizationId)

  const sortedOptions = useMemo(() => {
    if (geographiesData && organizationsData) {
      return sortChannelByState(options, geographiesData["states"])
    } else {
      return []
    }
  }, [options, organizationsData, geographiesData, filterParams])

  const organizationsHash: { [id: number]: BasicOrgType } = useMemo(() => {
    if (organizationsData && organizationsData.organizations) {
      const hash: { [id: number]: BasicOrgType } = {}
      organizationsData.organizations.forEach((org: BasicOrgType) => (hash[org.id] = org))

      return hash
    } else {
      return {}
    }
  }, [organizationsData])

  const filteredValues = useMemo(
    () => filterParams["organizations"].map((orgId: number) => organizationsHash[orgId]),
    [filterParams, options],
  )

  const filteredOptions = getFilteredOptions(filterParams, sortedOptions)

  const selectAllOption: BasicOrgType[] = [
    {
      id: -2,
      name: "Select All",
      state_name: "none",
    },
  ]

  return (
    <Autocomplete
      multiple
      disableCloseOnSelect
      noOptionsText="Nothing to display"
      id="organizations-multiple-select"
      ListboxComponent={styledGroupTitles}
      options={filteredOptions?.length > 0 ? [...selectAllOption, ...filteredOptions] : []}
      value={filteredValues || []}
      open={open}
      onOpen={() => setIsOpen(true)}
      onClose={() => setIsOpen(false)}
      onClick={() => setIsOpen(true)}
      renderTags={() => (
        <Typography onClick={() => setIsOpen(!open)} sx={{ maxWidth: "calc(100% - 40px)" }} noWrap>
          {filteredValues
            .map((item) => item?.name)
            .join(", ")
            .replace(/,\s*$/, "")}
        </Typography>
      )}
      onChange={(_event, newValue) => {
        const temp = { ...filterParams }
        if (newValue[newValue.length - 1]?.name === "Select All") {
          temp["organizations"] = filteredOptions.map((org) => org.id)
        } else {
          temp["organizations"] = newValue.map((org) => org.id)
        }
        setFilterParams(temp)
      }}
      groupBy={(option) => (option?.state?.name && option.state.name !== "none" ? option.state.name : "")}
      renderInput={(params) => <TextField {...params} label="Channel" {...textFieldProps} />}
      getOptionLabel={(option) => option?.name || "None"}
      isOptionEqualToValue={(option, value) => option.id === value?.id}
      renderOption={(props, option) => {
        return (
          <li {...props} key={option.id}>
            <Typography variant="body1">{option?.name}</Typography>
          </li>
        )
      }}
      sx={sx}
      size={size}
    />
  )
}
