import { FC, useMemo } from "react"
import { FormControl, Grid, InputLabel, MenuItem, Select, TextField } from "@mui/material"
import { useFetchIndustries } from "../../../api/industries"
import { Industry } from "../../../interfaces/industry"

export const IndustryStep: FC<{
  userIndustry: number
  setUserIndustry: any
  otherInput: string
  setOtherInput: any
}> = ({ userIndustry, setUserIndustry, otherInput, setOtherInput }) => {
  const { data: industries } = useFetchIndustries()
  const sortedIndustries = useMemo(
    () =>
      industries?.sort((a: Industry, b: Industry) => {
        if (a.name === "Other") {
          return 1
        } else if (b.name === "Other") {
          return -1
        } else if (a.name > b.name) {
          return 1
        } else if (a.name < b.name) {
          return -1
        } else {
          return 0
        }
      }),
    [industries],
  )
  const otherRecords = useMemo(
    () => industries?.filter((industry: Industry) => industry.name === "Other"),
    [industries],
  )

  return (
    <Grid container direction="column" justifyContent="center" marginTop={2}>
      <Grid item xs={6}>
        <FormControl fullWidth>
          <InputLabel id="industry-label">Industry</InputLabel>
          <Select
            fullWidth
            label="Industry"
            labelId="industry-label"
            value={userIndustry}
            onChange={(event: any) => setUserIndustry(event.target.value)}
          >
            {sortedIndustries?.map((industry: Industry) => (
              <MenuItem key={industry.id} value={industry.id}>
                {industry.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid marginTop={2} item xs={6} hidden={userIndustry !== (otherRecords || [])[0]?.id}>
        <TextField
          value={otherInput}
          placeholder="What's your industry?"
          onChange={(event: any) => setOtherInput(event.target.value)}
          fullWidth
        />
      </Grid>
    </Grid>
  )
}
