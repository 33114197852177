import { FC } from "react"
import { DataGrid } from "@mui/x-data-grid"
import { useFetchScheduledVideos } from "@src/api/admin/content-acquisition/videos"
import { GridColDef } from "@mui/x-data-grid/models/colDef"
import { theme } from "@src/theme"
import { ViewCloudRunJobLogs } from "./ViewJobLogs"

export const VideoTable: FC = () => {
  const { data: videos, isLoading } = useFetchScheduledVideos()

  const videoColumns: GridColDef[] = [
    { field: "id", headerName: "ID", flex: 0.25, type: "number" },
    { field: "url", headerName: "Source Video", flex: 1 },
    { field: "checksum", headerName: "Checksum", flex: 1 },
    { field: "bucket", headerName: "Bucket", flex: 1 },
    { field: "success", headerName: "Success", flex: 1, type: "boolean" },
    { field: "attempts", headerName: "Attempts", flex: 0.5, type: "number" },
    { field: "error", headerName: "Error", flex: 1 },
    { field: "meeting_id", headerName: "Meeting Id", flex: 1 },
    {
      field: "transcription_job_id",
      headerName: "Transcription Job Id",
      flex: 1,
    },
    {
      field: "scheduled_at",
      headerName: "Scheduled At",
      valueGetter: (value) => new Date(value as string),
      renderCell: (params) => new Date(params.value as string).toLocaleString(),
      type: "dateTime",
      description: "The time the video was sent to Bombus",
      flex: 1,
    },
    {
      field: "viewJobLog",
      headerName: "",
      flex: 0.5,
      renderCell: (params) =>
        params.row.attempts > 0 ? <ViewCloudRunJobLogs videoId={params.row.id as number} /> : null,
    },
  ]
  const date = new Date()
  //todo: change scheduled_at to created_at when fix is in, then remove scheduled_at column
  // put errors back when they are fixed
  return (
    <DataGrid
      sx={{
        '.MuiDataGrid-booleanCell[data-value="true"]': {
          color: theme.palette.success.main,
        },
        '.MuiDataGrid-booleanCell[data-value="false"]': {
          color: "red",
        },
      }}
      density="compact"
      loading={isLoading}
      rows={videos || []}
      columns={videoColumns.map((column) => ({ ...column, headerClassName: "super-app-theme--header" }))}
      initialState={{
        filter: {
          filterModel: {
            items: [{ field: "scheduled_at", operator: "onOrAfter", value: date.setDate(date.getDate() - 1) }],
          },
        },
        sorting: {
          sortModel: [{ field: "scheduled_at", sort: "desc" }],
        },
        columns: {
          columnVisibilityModel: {
            checksum: false,
            bucket: false,
            error: false,
          },
        },
        pagination: { paginationModel: { pageSize: 25 } },
      }}
    />
  )
}
