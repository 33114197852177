import { Link, useLocation } from "react-router-dom"
import { Tabs, Tab } from "@mui/material"
import { useFetchCurrentUser, useIsContractor, useIsSuperAdmin } from "../../api/users"
import { FC } from "react"
import { a11yProps } from "../shared/config"
import { useCanSeeAdmin } from "./config"

const createTabProps = (label: string, subUrl: string) => ({
  label,
  value: subUrl,
  component: Link,
  to: `/admin/${subUrl}`,
  ...a11yProps(subUrl),
  sx: {
    "&:hover": {
      textDecoration: "unset",
    },
    "&:active": {
      textDecoration: "unset",
    },
    "&:focus": {
      textDecoration: "unset",
    },
  },
})

const adminTabs = [
  ["Channels", "channels"],
  ["Channel Types", "channel_types"],
  ["User Organizations", "user_organizations"],
  ["Geographies", "geographies"],
  ["Users", "users"],
  ["Meetings", "meetings"],
  ["Roles", "roles"],
  ["Industries", "industries"],
  ["Persons", "persons"],
  ["Trial Users", "trial_users"],
  ["UAT", "uat"],
  ["Monitors", "monitors"],
  ["Hits Table", "hits_table"],
  ["ML Prompts", "prompts"],
  ["Proxies", "proxies"],
  ["Flags", "flags"],
  ["Other", "other"],
]

export const AdminNav: FC = () => {
  const { pathname } = useLocation()
  const { data: isSuperAdmin } = useIsSuperAdmin()
  const { data: isContractor } = useIsContractor()
  const { data: currentUser } = useFetchCurrentUser()
  const { data: canSeeAdmin } = useCanSeeAdmin()
  if (pathname.slice(0, 6) === "/admin" && canSeeAdmin) {
    return (
      <Tabs value={pathname.split("/")[2]} aria-label="settings tabs" variant="scrollable" allowScrollButtonsMobile>
        {adminTabs
          .filter(
            ([, subUrl]) => isSuperAdmin || isContractor || currentUser?.available_routes?.includes(`admin/${subUrl}`),
          )
          .map(([label, subUrl], index) => (
            <Tab {...createTabProps(label, subUrl)} key={index} />
          ))}
      </Tabs>
    )
  }

  return null
}
