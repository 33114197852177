import { FC, useState } from "react"
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Stack,
  CircularProgress,
  Card,
  CardContent,
} from "@mui/material"
import { getMeetingVoices, useRunIdentifySpeakers } from "../../../../api/admin/meetings"
import { useFetchPersons } from "../../../../api/admin/persons"
import { VoiceRow } from "./VoiceRow"
import { useToast } from "../../../shared/ToastHook"
import { useParams } from "react-router-dom"
import { IMeetingRouteParams } from "../../../../api/interfaces"

export const SpeakerIdentificationTable: FC = () => {
  const { meetingId } = useParams<IMeetingRouteParams>()
  const { data: voiceData, refetch } = getMeetingVoices(meetingId)
  const { refetch: refetchPersons } = useFetchPersons(new URLSearchParams({ count: "1000000" }))
  const [isLoading, setIsLoading] = useState(false)
  const toast = useToast()
  const { mutate: runIdentifySpeakers } = useRunIdentifySpeakers(meetingId, () => {
    toast("Speaker identification started")
    setIsLoading(true)
    setTimeout(() => {
      setIsLoading(false)
      refetch()
      refetchPersons()
    }, 60000)
  })

  return (
    <Card>
      <CardContent>
        <Stack direction="row" spacing={1}>
          <Button variant="contained" onClick={() => runIdentifySpeakers()} disabled={isLoading}>
            Run Speaker Identification
          </Button>
          {isLoading && <CircularProgress />}
        </Stack>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Voice_id</TableCell>
                <TableCell>Speaker</TableCell>
                <TableCell sx={{ minWidth: "36ch" }}>Person_id</TableCell>
                <TableCell sx={{ minWidth: "24ch" }}>Person Name</TableCell>
                <TableCell sx={{ minWidth: "24ch" }}>Person Title</TableCell>
                <TableCell sx={{ minWidth: "24ch" }}>Person Organization</TableCell>
                <TableCell>Update</TableCell>
                <TableCell>Verified</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{voiceData?.map((voice) => <VoiceRow key={voice.id} voice={voice} />)}</TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  )
}
