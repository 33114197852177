import { Dispatch, FC, SetStateAction, useState, useEffect } from "react"
import { Stack, Skeleton, Autocomplete, TextField, Box, Button } from "@mui/material"
import { useLocation } from "react-router-dom"
import { urlParamsToOpportunityQueryObject } from "../../api/opportunities"
import { ChannelSelectorMultiple } from "../shared/Selectors/Multiple/Channel"
import { GeographySelectorMultiple } from "../shared/Selectors/Multiple/Geographies"
import { useFetchOrganizations } from "../../api/organizations"
import { useFetchGeographies } from "../../api/geographies"
import { FilterParams } from "../Home/interface"
import useSetUrlParamState from "../shared/UseSetUrlParamState"
import { ChipField } from "@src/components/shared/ChipField"
import { useFetchNaicsCodesMapByCode } from "@src/api/naicsCodes"
import { CampaignFields } from "./CampaignFields"

export const OpportunityTableSearchForm: FC = () => {
  const paramsString = useLocation().search
  const params = new URLSearchParams(paramsString)

  const campaignIdString = params.get("campaign")
  const campaignId = campaignIdString ? parseInt(campaignIdString, 10) : undefined

  const urlParamState = urlParamsToOpportunityQueryObject(params)
  const [terms, setTerms] = useState(new Set<string>(urlParamState.terms))
  useEffect(() => {
    setTerms(new Set<string>(urlParamState.terms))
  }, [paramsString])

  const { data: organizationsData, isFetched: organizationsIsFetched } = useFetchOrganizations()
  const { data: geographiesData, isFetched: geographiesIsFetched } = useFetchGeographies()
  const { data: naicsCodesMap } = useFetchNaicsCodesMapByCode()

  const setUrlParamState = useSetUrlParamState<typeof urlParamState & { campaign?: string }>()

  const setUrlParamStateWithPage0 = ((newState: typeof urlParamState) =>
    setUrlParamState({ ...newState, campaign: campaignId ? `${campaignId}` : undefined, page: 0 })) as Dispatch<
    SetStateAction<FilterParams>
  >

  return (
    <Stack gap={1}>
      <ChipField terms={terms} setTerms={setTerms} placeholder="What opportunities would you like to find?" />
      <Autocomplete
        multiple
        value={urlParamState.naics_code}
        onChange={(_, newValue) =>
          setUrlParamState({
            ...urlParamState,
            naics_code: newValue,
            page: 0,
            campaign: campaignId ? `${campaignId}` : undefined,
          })
        }
        getOptionLabel={(option) => {
          const naicsCode = naicsCodesMap?.get(option)
          return `${naicsCode?.code}: ${naicsCode?.title}`
        }}
        options={naicsCodesMap ? Array.from(naicsCodesMap.keys()) : []}
        renderInput={(params) => <TextField {...params} label="NAICS Code" />}
        renderOption={(props, option) => {
          const naicsCode = naicsCodesMap?.get(option)
          return (
            <Box component="li" {...props} key={option}>
              {`${naicsCode?.code}: ${naicsCode?.title}`}
            </Box>
          )
        }}
      />
      <Stack direction="row" gap={1} flexWrap="wrap">
        {!geographiesIsFetched || !geographiesData ? (
          <>
            <Skeleton height={56} variant="rounded" sx={{ flex: "20ch 1 0" }} />
            <Skeleton height={56} variant="rounded" sx={{ flex: "20ch 1 0" }} />
            <Skeleton height={56} variant="rounded" sx={{ flex: "30ch 1 0" }} />
          </>
        ) : (
          <>
            <GeographySelectorMultiple
              options={geographiesData}
              label="State"
              type="states"
              filterParams={urlParamState}
              setFilterParams={setUrlParamStateWithPage0}
              sx={{ flex: "20ch 1 0" }}
            />
            <GeographySelectorMultiple
              options={geographiesData}
              label="County"
              type="counties"
              filterParams={urlParamState}
              setFilterParams={setUrlParamStateWithPage0}
              sx={{ flex: "20ch 1 0" }}
            />
            <GeographySelectorMultiple
              options={geographiesData}
              label="City"
              type="cities"
              filterParams={urlParamState}
              setFilterParams={setUrlParamStateWithPage0}
              sx={{ flex: "30ch 1 0" }}
            />
          </>
        )}
        {!organizationsIsFetched ? (
          <Skeleton height={56} variant="rounded" sx={{ flex: "30ch 1 0" }} />
        ) : (
          <ChannelSelectorMultiple
            options={organizationsData ? organizationsData.organizations : []}
            filterParams={urlParamState}
            setFilterParams={setUrlParamStateWithPage0}
            sx={{ flex: "30ch 1 0" }}
          />
        )}
      </Stack>
      <Stack direction="row" gap={1}>
        <CampaignFields />
        <Button
          variant="contained"
          size="large"
          onClick={() =>
            setUrlParamState({
              ...urlParamState,
              terms: Array.from(terms),
              campaign: campaignId ? `${campaignId}` : undefined,
              page: 0,
            })
          }
        >
          Search
        </Button>
      </Stack>
    </Stack>
  )
}
