import { FC, useState } from "react"
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from "@mui/material"
import Loading from "@components/shared/Loading"
import { useFetchOrganizationsCampaigns } from "@api/campaigns"
import { useAddRecommendedCampaign, useRemoveRecommendedCampaign } from "@api/admin/industries"
import { FetchedIndustry, FetchedIndustryRecommendedCampaign } from "@interfaces/industry"

export const RecommendedCampaigns: FC<{
  industry: FetchedIndustry
}> = ({ industry }) => {
  const [openDialog, setOpenDialog] = useState(false)
  const [openForm, setOpenForm] = useState(false)
  const [useCase, setUseCase] = useState("")
  const [campaignId, setCampaignId] = useState(0)
  const { data: campaigns } = useFetchOrganizationsCampaigns()
  const { mutate: addRecommendedCampaign } = useAddRecommendedCampaign()
  const { mutate: removeRecommendedCampaign } = useRemoveRecommendedCampaign()

  if (industry === undefined) {
    return (
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)} fullWidth>
        <Loading />
      </Dialog>
    )
  }

  return (
    <>
      <Stack direction="row" spacing={1}>
        <Button variant="contained" onClick={() => setOpenDialog(true)}>
          Recommended Campaigns
        </Button>
      </Stack>
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)} fullWidth>
        <DialogTitle>Recommended Campaigns</DialogTitle>
        <DialogContent>
          <Grid container marginBottom={1} spacing={2} direction="column">
            {industry?.recommended_campaigns?.map((recommendedCampaign: FetchedIndustryRecommendedCampaign) => (
              <Grid item container key={recommendedCampaign.id} alignItems="center">
                <Typography variant="h6">
                  {industry?.campaigns?.find((campaign) => campaign.id === recommendedCampaign.campaign.id)?.name}
                </Typography>
                <Typography variant="body1">
                  &nbsp;
                  {`(${recommendedCampaign.use_case})`}
                </Typography>
                <Button
                  onClick={() => {
                    removeRecommendedCampaign({
                      recommendedCampaignId: recommendedCampaign.id,
                      industryId: industry.id,
                    })
                  }}
                >
                  Remove
                </Button>
              </Grid>
            ))}
          </Grid>
          <Divider />
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Button onClick={() => setOpenForm(!openForm)}>Add Recommended Campaign</Button>
            </Grid>
            <Grid hidden={openForm === false} item xs={12}>
              <Grid container>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <InputLabel id="campaign-id-label">Select the Campaign</InputLabel>
                    <Select
                      value={campaignId.toString()}
                      onChange={(event: SelectChangeEvent) => setCampaignId(parseInt(event.target.value))}
                      labelId="campaign-id-label"
                      label="Select The Campaign"
                    >
                      {campaigns?.campaigns
                        ?.sort((a: any, b: any) => (a.name > b.name ? 1 : -1))
                        .map((channel_type: any) => (
                          <MenuItem key={channel_type.id} value={channel_type.id}>
                            {channel_type.name}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <InputLabel id="use-case-label">Use Case</InputLabel>
                    <Select
                      value={useCase}
                      onChange={(event: SelectChangeEvent) => setUseCase(event.target.value)}
                      labelId="use-case-label"
                      label="Use Case"
                    >
                      {industry?.use_cases?.map((use_case: string) => (
                        <MenuItem key={use_case} value={use_case}>
                          {use_case}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item>
                  <Button
                    onClick={() =>
                      addRecommendedCampaign({
                        industry_id: industry.id,
                        campaign_id: campaignId,
                        use_case: useCase,
                      })
                    }
                    disabled={campaignId === 0 || useCase === ""}
                  >
                    Create
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  )
}
