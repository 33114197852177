import { Dispatch, FC, SetStateAction, useState } from "react"
import {
  Stack,
  Skeleton,
  Autocomplete,
  TextField,
  FormControl,
  InputLabel,
  InputAdornment,
  IconButton,
  OutlinedInput,
} from "@mui/material"
import { useLocation } from "react-router-dom"
import { urlParamsToBriefingQueryObject } from "../../api/briefings"
import { ChannelSelectorMultiple } from "../shared/Selectors/Multiple/Channel"
import { GeographySelectorMultiple } from "../shared/Selectors/Multiple/Geographies"
import { useFetchOrganizations } from "../../api/organizations"
import { useFetchGeographies } from "../../api/geographies"
import { useFetchUsers } from "../../api/users"
import { FilterParams } from "../Home/interface"
import { getPrompts } from "../../api/prompts"
import SearchIcon from "@mui/icons-material/Search"
import CloseIcon from "@mui/icons-material/Close"
import useSetUrlParamState from "../shared/UseSetUrlParamState"

const TEXT_SEARCH_LABEL = "Briefing Text Search"

export const BriefingTableSearchForm: FC = () => {
  const params = new URLSearchParams(useLocation().search)
  const { data: organizationsData, isFetched: organizationsIsFetched } = useFetchOrganizations()
  const { data: geographiesData, isFetched: geographiesIsFetched } = useFetchGeographies()
  const { data: prompts, isFetched: promptsIsFetched } = getPrompts("personal")
  const { data: users, isFetched: usersIsFetched } = useFetchUsers()
  const usersMap = new Map(users?.map(({ id, email }) => [id, email]))

  const urlParamState = urlParamsToBriefingQueryObject(params)
  const setUrlParamState = useSetUrlParamState<typeof urlParamState>()
  const [searchText, setSearchText] = useState(urlParamState.text)

  const setSearchTextUrlParamState = () => setUrlParamState({ ...urlParamState, text: searchText, page: 0 })
  const setUrlParamStateWithPage0 = ((newState: typeof urlParamState) =>
    setUrlParamState({ ...newState, page: 0 })) as Dispatch<SetStateAction<FilterParams>>

  return (
    <>
      <Stack direction="row" marginTop={2} gap={1} flexWrap="wrap">
        <FormControl sx={{ flex: "40ch 1 0" }} variant="outlined">
          <InputLabel>{TEXT_SEARCH_LABEL}</InputLabel>
          <OutlinedInput
            value={searchText}
            onChange={(event) => setSearchText(event.target.value)}
            onKeyPress={(event) => {
              if (event.key === "Enter") {
                setSearchTextUrlParamState()
              }
            }}
            label={TEXT_SEARCH_LABEL}
            endAdornment={
              <InputAdornment position="end">
                {searchText && (
                  <IconButton
                    onClick={() => {
                      setSearchText("")
                      setUrlParamState({ ...urlParamState, text: "", page: 0 })
                    }}
                    edge="end"
                  >
                    <CloseIcon />
                  </IconButton>
                )}
                <IconButton onClick={setSearchTextUrlParamState} edge="end">
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
        <Stack gap={1} direction="row" flex="70ch 1 0">
          {!promptsIsFetched || !prompts ? (
            <Skeleton height={56} variant="rounded" sx={{ flex: 1 }} />
          ) : (
            <Autocomplete
              multiple
              options={Array.from(prompts.map(({ name }) => name))}
              renderInput={(params) => <TextField {...params} label="Type" />}
              sx={{ flex: 1 }}
              value={urlParamState.types}
              onChange={(_event, value) => setUrlParamState({ ...urlParamState, types: value || [], page: 0 })}
            />
          )}
          {!usersIsFetched || !users ? (
            <Skeleton height={56} variant="rounded" sx={{ flex: 1 }} />
          ) : (
            <Autocomplete
              multiple
              options={Array.from(users.map(({ id }) => id))}
              renderInput={(params) => <TextField {...params} label="User" />}
              sx={{ flex: 1 }}
              getOptionLabel={(option) => usersMap.get(option) || ""}
              value={urlParamState.users}
              onChange={(_event, value) => setUrlParamState({ ...urlParamState, users: value || [], page: 0 })}
            />
          )}
        </Stack>
      </Stack>
      <Stack direction="row" gap={1} marginTop={1}>
        {!geographiesIsFetched || !geographiesData ? (
          <>
            <Skeleton height={56} variant="rounded" sx={{ flex: "20ch 1 0" }} />
            <Skeleton height={56} variant="rounded" sx={{ flex: "20ch 1 0" }} />
            <Skeleton height={56} variant="rounded" sx={{ flex: "30ch 1 0" }} />
          </>
        ) : (
          <>
            <GeographySelectorMultiple
              options={geographiesData}
              label="State"
              type="states"
              filterParams={urlParamState}
              setFilterParams={setUrlParamStateWithPage0}
              sx={{ flex: "20ch 1 0" }}
            />
            <GeographySelectorMultiple
              options={geographiesData}
              label="County"
              type="counties"
              filterParams={urlParamState}
              setFilterParams={setUrlParamStateWithPage0}
              sx={{ flex: "20ch 1 0" }}
            />
            <GeographySelectorMultiple
              options={geographiesData}
              label="City"
              type="cities"
              filterParams={urlParamState}
              setFilterParams={setUrlParamStateWithPage0}
              sx={{ flex: "30ch 1 0" }}
            />
          </>
        )}
        {!organizationsIsFetched ? (
          <Skeleton height={56} variant="rounded" sx={{ flex: "30ch 1 0" }} />
        ) : (
          <ChannelSelectorMultiple
            options={organizationsData ? organizationsData.organizations : []}
            filterParams={urlParamState}
            setFilterParams={setUrlParamStateWithPage0}
            sx={{ flex: "30ch 1 0" }}
          />
        )}
      </Stack>
    </>
  )
}
