import { FC, ReactElement, useState } from "react"
import { CircularProgress, Card, CardContent, CardActions, Typography, Button, IconButton, Stack } from "@mui/material"
import { PublicBriefing } from "../../../interfaces/briefing"
import { marked } from "marked"
import { sanitize } from "dompurify"
import { useToast } from "../ToastHook"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import ExpandLessIcon from "@mui/icons-material/ExpandLess"
import ContentCopyIcon from "@mui/icons-material/ContentCopy"

export const BriefingCard: FC<{
  briefing: PublicBriefing
}> = ({ briefing: { name, result, summary, created_at } }) => {
  const [isFull, setIsFull] = useState(false)
  const toast = useToast()

  let briefingContent: ReactElement
  if (result || summary) {
    briefingContent = (
      <div
        dangerouslySetInnerHTML={{
          __html: sanitize(marked.parse(isFull ? result : summary) as string),
        }}
      />
    )
  } else {
    const createDate = new Date(created_at)
    if (Date.now() - createDate.valueOf() > 120000) {
      // 2 * 60 * 1000 = 2 minutes
      briefingContent = (
        <Typography>The generation of this briefing has errored. Please contact Cloverleaf AI.</Typography>
      )
    } else {
      briefingContent = <CircularProgress />
    }
  }

  return (
    <Card>
      <CardContent>
        <Stack direction="row" alignItems="center">
          <Typography variant="h5" flex="1">
            {name}
          </Typography>
          <IconButton
            onClick={() => {
              navigator.clipboard.writeText(isFull ? result : summary)
              toast("Copied to clipboard")
            }}
          >
            <ContentCopyIcon />
          </IconButton>
        </Stack>
        {briefingContent}
      </CardContent>
      {(result || summary) && (
        <CardActions>
          <Button
            onClick={() => setIsFull((prevValue) => !prevValue)}
            startIcon={isFull ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            variant="outlined"
          >
            {isFull ? "Show short briefing section" : "Show full briefing section"}
          </Button>
        </CardActions>
      )}
    </Card>
  )
}
