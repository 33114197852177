import { FC } from "react"
import {
  Stack,
  TableRow,
  TableCell,
  Link as MuiLink,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
} from "@mui/material"
import { Link } from "react-router-dom"
import { meetingLocationString } from "../shared/config"
import { FetchedMeeting } from "../../api/meetings"
import { PublicBriefing } from "../../interfaces/briefing"
import { useModalKey, useOpenModalKey, useCloseModalKey } from "../shared/OpenModalHook"
import { BriefingContent } from "../MeetingDetail/Shared/BriefingContent"

const datestringToLocaleDate = (dateString: string) => new Date(dateString).toLocaleDateString()

export const BriefingTableRow: FC<{ briefing: PublicBriefing }> = ({ briefing }) => {
  const briefingModalKey = `${briefing.id} briefing modal`
  const summaryModalKey = `${briefing.id} summary modal`
  const briefingOpened = useModalKey(briefingModalKey)
  const summaryOpened = useModalKey(summaryModalKey)
  const openBriefing = useOpenModalKey(briefingModalKey)
  const openSummary = useOpenModalKey(summaryModalKey)
  const closeBriefing = useCloseModalKey(briefingModalKey)
  const closeSummary = useCloseModalKey(summaryModalKey)

  return (
    <TableRow key={briefing.id}>
      <TableCell>
        <MuiLink
          component={Link}
          to={`/meetings/${briefing.meeting.id}#${briefing.is_personalized ? "personal-briefing" : "briefing"}`}
        >
          {briefing.meeting.title}
        </MuiLink>
      </TableCell>
      <TableCell>{datestringToLocaleDate(briefing.meeting.published_at)}</TableCell>
      <TableCell>
        <MuiLink component={Link} to={`/channel-details/${briefing.meeting.organization_id}`}>
          {briefing.meeting.organization_name}
        </MuiLink>
      </TableCell>
      <TableCell>{meetingLocationString(briefing.meeting as FetchedMeeting)}</TableCell>
      <TableCell>{briefing.name}</TableCell>
      <TableCell>{briefing.user_email}</TableCell>
      <TableCell>{datestringToLocaleDate(briefing.updated_at)}</TableCell>
      <TableCell>
        <Stack spacing={1}>
          <Button variant="contained" sx={{ textWrap: "nowrap" }} onClick={openBriefing}>
            Show Briefing
          </Button>
          {briefing.summary && (
            <Button variant="contained" sx={{ textWrap: "nowrap" }} onClick={openSummary}>
              Show Summary
            </Button>
          )}
        </Stack>
        <Dialog open={briefingOpened} onClose={closeBriefing} fullWidth>
          <DialogTitle>{`${briefing.meeting.title} ${briefing.name}`}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              <BriefingContent promptResult={briefing.result} />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeBriefing}>Close</Button>
          </DialogActions>
        </Dialog>
        {briefing.summary && (
          <Dialog open={summaryOpened} onClose={closeSummary} fullWidth>
            <DialogTitle>{`${briefing.meeting.title} ${briefing.name}`}</DialogTitle>
            <DialogContent>
              <DialogContentText>
                <BriefingContent promptResult={briefing.summary} />
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={closeSummary}>Close</Button>
            </DialogActions>
          </Dialog>
        )}
      </TableCell>
    </TableRow>
  )
}
