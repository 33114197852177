import { FC, useState, useMemo } from "react"
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SxProps,
  Theme,
  ListSubheader,
  Stack,
  Button,
  Skeleton,
} from "@mui/material"
import { Link } from "react-router-dom"
import { useFetchMyCampaigns, useFetchFilterList } from "../../../api/campaigns"
import { Campaign } from "../../../interfaces/campaign"
import { useFetchRecommendedCampaigns } from "../../../api/users"
import { useLocation } from "react-router-dom"
import { CampaignTileBase } from "../CampaignTileBase"
import { useFetchOrganizationsMap } from "@functions/organizations"
import { useFetchChannelTypesMap } from "../../../api/channel_types"
import { useFetchGeographiesMap } from "../../../api/geographies"
import { campaignWizardModalKey } from "../CampaignWizard"
import { useOpenModalKey } from "../OpenModalHook"
import { SearchQueryParams } from "../../../interfaces/search"

const CampaignTile: FC<{ campaign: Campaign }> = ({ campaign }) => {
  const { data: organizationsMap } = useFetchOrganizationsMap()
  const { data: geographiesMap } = useFetchGeographiesMap()
  const { data: channelTypesMap } = useFetchChannelTypesMap()
  return (
    <CampaignTileBase
      campaign={campaign}
      limit={5}
      organizationsMap={organizationsMap}
      geographiesMap={geographiesMap}
      channelTypesMap={channelTypesMap}
      sx={{ width: "100%" }}
    />
  )
}

const neutralStyle = {
  textDecoration: "none",
  color: "inherit",
}

const generateMenuItems = (campaigns?: Campaign[]) => {
  const locationSearchParams = useLocation().search
  if (!campaigns) {
    return null
  }

  return campaigns.map((campaign) => {
    const urlSearchParams = new URLSearchParams(locationSearchParams)
    urlSearchParams.delete(SearchQueryParams.SearchTerms)
    urlSearchParams.delete(SearchQueryParams.MustIncludeTerms)
    urlSearchParams.delete(SearchQueryParams.Exclude)
    urlSearchParams.set(SearchQueryParams.CampaignId, campaign.id.toString())
    return (
      <MenuItem
        key={campaign.id}
        component={Link as React.ElementType}
        to={{ search: urlSearchParams.toString() }}
        value={campaign.id}
        sx={{
          "&:focus": neutralStyle,
          "&:target": neutralStyle,
          "&:active": neutralStyle,
          "&:hover": neutralStyle,
        }}
        divider
      >
        <CampaignTile key={campaign.id} campaign={campaign} />
      </MenuItem>
    )
  })
}

export const CampaignSelect: FC<{
  sx?: SxProps<Theme>
}> = ({ sx }) => {
  const locationSearchParams = useLocation().search
  const campaignIdString = new URLSearchParams(locationSearchParams).get(SearchQueryParams.CampaignId)
  const [campaignId, setCampaignId] = useState(campaignIdString ? parseInt(campaignIdString) : undefined)
  const { data: myCampaignData } = useFetchMyCampaigns()
  const { data: campaignsData } = useFetchFilterList()
  const { data: recommendedCampaigns } = useFetchRecommendedCampaigns()
  const openCampaignWizard = useOpenModalKey(campaignWizardModalKey)

  const campaignNameMap = useMemo(() => {
    if (myCampaignData && campaignsData && recommendedCampaigns) {
      return new Map([
        ...myCampaignData.campaigns.map((campaign) => [campaign.id, campaign.name]),
        ...campaignsData.campaigns.map((campaign: Campaign) => [campaign.id, campaign.name]),
        ...recommendedCampaigns.map((campaign) => [campaign.id, campaign.name]),
      ])
    } else {
      return new Map()
    }
  }, [myCampaignData, campaignsData, recommendedCampaigns])

  return (
    <Stack direction="row" spacing={1} alignItems="center" sx={sx} width="100%">
      <FormControl fullWidth size="small">
        <InputLabel>Campaign</InputLabel>
        <Select
          value={campaignId}
          label="Campaign"
          onChange={(event) =>
            setCampaignId(typeof event.target.value === "number" ? event.target.value : parseInt(event.target.value))
          }
          renderValue={(value) => (campaignNameMap.get(value) ? `${campaignNameMap.get(value)}` : <Skeleton />)}
        >
          <ListSubheader>My Campaigns</ListSubheader>
          {generateMenuItems(myCampaignData?.campaigns)}
          <ListSubheader>All Campaigns</ListSubheader>
          {generateMenuItems(campaignsData?.campaigns)}
        </Select>
      </FormControl>
      <Button
        variant="contained"
        sx={{ flexShrink: "0", display: { xs: "none", sm: "flex" } }}
        onClick={openCampaignWizard}
      >
        Campaign Builder
      </Button>
    </Stack>
  )
}
