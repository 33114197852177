import { useMutation, useQuery, useQueryClient } from "react-query"
import { useAuthAxios } from "../axiosInstance"
import { Person } from "../../interfaces/person"

const prefix = "/admin/persons"

export const useFetchPersons = (filterParams?: URLSearchParams, include_voices?: boolean) => {
  const axiosInstance = useAuthAxios()

  const apiParams = new URLSearchParams({
    name: filterParams?.get("name") || "",
    page: filterParams?.get("page") || "0",
    count: filterParams?.get("count") || "25",
    organization: filterParams?.get("organization") || "",
    title: filterParams?.get("title") || "",
    sort_by: filterParams?.get("sort_by") || "id",
    sort_order: filterParams?.get("sort_order") || "asc",
  })
  if (include_voices) {
    apiParams.set("include_voices", "true")
  }

  return useQuery(
    [
      "admin",
      "persons",
      {
        name: apiParams?.get("name"),
        page: apiParams?.get("page"),
        count: apiParams?.get("count"),
        organization: apiParams?.get("organization"),
        title: apiParams?.get("title"),
        sort_by: apiParams?.get("sort_by"),
        sort_order: apiParams?.get("sort_order"),
        include_voices: include_voices,
      },
    ],
    async () => {
      const response = await (
        await axiosInstance
      ).get<{ persons: Person[]; total: number }>(`${prefix}?${apiParams?.toString()}`)

      return response.data
    },
  )
}

export const useFetchPersonsMap = (filterParams?: URLSearchParams) => {
  const fetchData = useFetchPersons(filterParams)
  if (fetchData.data) {
    return {
      ...fetchData,
      data: new Map(fetchData.data.persons.map((person) => [person.id, person])),
    }
  }
  return fetchData
}

export const useCreatePerson = (onSuccess?: () => void) => {
  const axiosInstance = useAuthAxios()
  const queryClient = useQueryClient()

  return useMutation(
    async (params: { name: string; title: string; organization: string }) => {
      return (await axiosInstance).post<Person>(`${prefix}`, params)
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["admin", "persons"])
        if (onSuccess) {
          onSuccess()
        }
      },
    },
  )
}

export const useUpdatePerson = (onSuccess?: () => void) => {
  const axiosInstance = useAuthAxios()
  const queryClient = useQueryClient()

  return useMutation(
    async (params: { id: number; name: string; title: string; organization: string }) => {
      return (await axiosInstance).put<Person>(`${prefix}/${params.id}`, params)
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["admin", "persons"])
        if (onSuccess) {
          onSuccess()
        }
      },
    },
  )
}
