import { FC, useState, useEffect, useContext } from "react"
import { Box, Stack } from "@mui/material"
import { Helmet } from "react-helmet"
import { Context, MeetingContext } from "../components/MeetingDetail/Shared/Context"
import { VideoCard } from "../components/MeetingDetail/VideoCard"
import Tabs from "../components/shared/Tabs/HashTabs"
import { TranscriptsTabContent } from "../components/MeetingDetail/TranscriptsTab"
import { StatisticsDetailsCard } from "../components/MeetingDetail/StatisticsDetailsCard"
import { useIsSuperAdmin, useIsBriefingsEnabled, useIsOpportunitiesEnabled } from "../api/users"
import { SendBriefingCard } from "../components/MeetingDetail/SendBriefingCard"
import { TimelineAccordion } from "../components/MeetingDetail/TimelineAccordion"
import { MobileVideo } from "../components/MeetingDetail/VideoCard/MobileVideo"
import { useLocation, useParams } from "react-router-dom"
import { IMeetingRouteParams } from "../api/interfaces"
import { useFetchMeeting } from "../api/meetings"
import { GeneralBriefingCard } from "../components/MeetingDetail/BriefingTab"
import { useMarkViewed } from "../api/meetings"
import { SpeakerIdentificationTable } from "../components/Admin/Meetings/SpeakerIdentificationTable"
import { useFetchCurrentUser } from "../api/users"
import { PersonalBriefing } from "../components/MeetingDetail/PersonalBriefingTab"
import { Opportunities } from "../components/MeetingDetail/OpportunitiesTab"
import Loading from "@src/components/shared/Loading"

export const MeetingPage: FC = () => {
  const { data: isSuperAdmin } = useIsSuperAdmin()
  const { meeting, setMeeting } = useContext(MeetingContext)
  const { meetingId } = useParams<IMeetingRouteParams>()
  const { data: meetingData, isLoading } = useFetchMeeting(meetingId, (data) => {
    setMeeting(data)
  })
  const { data: briefingsEnabled } = useIsBriefingsEnabled()
  const { data: opportunitiesEnabled } = useIsOpportunitiesEnabled()

  const { data: currentUser } = useFetchCurrentUser()

  const searchString = useLocation().search
  const params = new URLSearchParams(searchString)
  const startTimeSeconds = params.get("startTimeSeconds")
  const { mutate } = useMarkViewed(meetingId)

  const [state, setState] = useState({
    progress: [0, 0],
    videoStartTime: startTimeSeconds
      ? { startTime: parseInt(startTimeSeconds), change: false }
      : { startTime: 0, change: false },
    playingVideo: false,
    isReady: false,
  })

  useEffect(() => {
    mutate()
    window.scrollTo(0, 0)
  }, [])

  if (isLoading || !meetingData) {
    return <Loading />
  }

  const tabs = [
    {
      hash: "",
      label: "Transcripts",
      component: <TranscriptsTabContent meetingData={meetingData?.meeting} />,
    },
    {
      hash: "briefing",
      label: "Briefing",
      component: <GeneralBriefingCard />,
    },
  ]

  if (briefingsEnabled) {
    tabs.push({
      hash: "personal-briefing",
      label: "Personal Briefing",
      component: <PersonalBriefing />,
    })
  }

  if (opportunitiesEnabled) {
    tabs.push({
      hash: "opportunities",
      label: "Opportunities",
      component: <Opportunities id={meetingId} />,
    })
  }

  return (
    <Context.Provider value={{ state, setState }}>
      <MeetingContext.Provider value={{ meeting, setMeeting }}>
        <Helmet>
          <title>{`Cloverleaf.AI | ${meetingData.meeting.title || "Meeting"}`}</title>
        </Helmet>
        <Box
          marginBottom={{
            xs: `${window.innerWidth * 0.5625 + 16}px`,
            md: "0",
          }}
        >
          <Stack direction="row">
            <Stack direction="column" padding={2} spacing={2} width={{ xs: "100%", md: "50%" }}>
              <VideoCard />
              <Box display={{ md: "none" }}>
                <Tabs display={{ md: "none" }} gap={1} tabs={tabs} />
              </Box>
              <StatisticsDetailsCard />
              {currentUser?.permissions?.includes("speaker_identification") && <SpeakerIdentificationTable />}
              {isSuperAdmin && <SendBriefingCard />}
              {process.env.REACT_APP_GOOGLE_ENV !== "production" && <TimelineAccordion />}
            </Stack>
            <Stack padding={2} spacing={2} width={{ xs: "100%", md: "50%" }} display={{ xs: "none", md: "flex" }}>
              <Tabs gap={1} tabs={tabs} />
            </Stack>
          </Stack>
        </Box>
        <MobileVideo />
      </MeetingContext.Provider>
    </Context.Provider>
  )
}
