import { useEffect, FC, createContext } from "react"
import { Redirect, Switch, Route } from "react-router-dom"
import { Box, Typography } from "@mui/material"
import { QueryClientProvider, QueryClient } from "react-query"
import { Stack, Toolbar } from "@mui/material"
import { Nav } from "./components/Nav/Nav"
import { Footer } from "./components/Nav/Footer"
import ProtectedRoute from "./auth/protectedRoute"
import ProtectedAdminRoute from "./auth/protectedAdminRoute"
import { queryClient } from "./api/api"
import { UnsubscribePage } from "./pages/UnsubscribePage"
import "./App.css"
import { useAuthAxios } from "./api/axiosInstance"
import { HomePage } from "./pages/HomePage"
import { MeetingPage } from "./pages/MeetingPage"
import { CampaignsPage } from "./pages/CampaignsPage"
import { AdminPage } from "./pages/AdminPage"
import { UsersPage } from "./pages/UsersPage"
import BookmarksPage from "./pages/BookmarksPage"
import { DashboardsPage } from "./pages/DashboardsPage"
import { DashboardDetailPage } from "./pages/DashboardDetailPage"
import { AxiosProviderNoAuth } from "./api/axiosInstanceNoAuth"
import { SharePage } from "./pages/SharePage"
import { ChannelDetailPage } from "./pages/ChannelDetailPage"
import { ChannelDirectoryPage } from "./pages/ChannelDirectoryPage"
import { AccountPage } from "./pages/AccountPage"
import { Init } from "./components/Init"
import { AdminNav } from "./components/Nav/AdminNav"
import { SearchPromotionPage } from "./pages/SearchPromotionPage"
import { BriefingsPage } from "./pages/BriefingsPage"
import { OpportunitiesPage } from "./pages/OpportunitiesPage"
import { PromoNav } from "./components/Nav/PromoNav"
import { FreeUserPage } from "./pages/FreeUserPage"
import { PersonPage } from "./pages/PersonPage"
import { NoAuthSharePage } from "./pages/NoAuthSharePage"
import { FindALeadPromotionPage } from "./pages/FindALead"
import { FindALeadIndustryPage } from "./pages/FindALead/industry"
import { MeetingShare } from "./pages/FindALead/meeting"
import { InterestedForm } from "./pages/FindALead/InterestedForm"
import HotJar from "./components/shared/Hotjar"

const RedirectComponent: FC = () => {
  useEffect(() => {
    window.location.href = "/"
  }, [])
  return <Typography> Redirecting... </Typography>
}

const Routes: FC = () => {
  return (
    <Switch>
      <Route component={FreeUserPage} path="/free-account" exact />
      <Route component={UnsubscribePage} path="/unsubscribe/:id" exact />
      <Route component={RedirectComponent} path="/redirect" exact />
      <Route component={SearchPromotionPage} path="/search" />
      <Route component={MeetingShare} path="/find-a-lead/:leadId/meeting/:meetingId/clip/:clipId" exact />
      <Route component={InterestedForm} path="/find-a-lead/:id/form" exact />
      <Route component={FindALeadIndustryPage} path="/find-a-lead/:id" exact />
      <Route component={FindALeadPromotionPage} path="/find-a-lead" />
      <Route component={NoAuthSharePage} path="/free/:id" exact />
      <ProtectedRoute component={SharePage} path="/share/:id" exact signUp />
      <ProtectedRoute component={HomePage} path="/" exact />
      <ProtectedRoute component={MeetingPage} path="/meetings/:meetingId" exact />
      <ProtectedRoute component={DashboardsPage} path="/dashboards" exact />
      <ProtectedRoute component={DashboardDetailPage} path="/dashboards/:dashboardId" exact />
      <ProtectedRoute component={CampaignsPage} path="/campaigns" exact />
      <ProtectedRoute component={UsersPage} path="/users" exact />
      <ProtectedRoute component={BookmarksPage} path="/bookmarks" exact />
      <ProtectedRoute component={ChannelDetailPage} path="/channel-details/:organizationId" exact />
      <ProtectedRoute component={ChannelDirectoryPage} path="/channels" exact />
      <ProtectedRoute component={AccountPage} path="/account" />
      <ProtectedRoute component={BriefingsPage} path="/briefings" />
      <ProtectedRoute component={OpportunitiesPage} path="/opportunities" />
      <ProtectedRoute component={PersonPage} path="/persons/:personId" />
      <ProtectedAdminRoute component={AdminPage} path="/admin" />
      <Redirect to="/redirect" />
    </Switch>
  )
}

export const PromoContext = createContext(false)

function App() {
  // do not put any hooks here, this will cause react-query to clear on route change!
  const axiosInstance = useAuthAxios()
  const pathname = window.location.pathname
  const promotionRoutes = ["/unsubscribe", "/search", "/free-account", "/free", "/find-a-lead"]
  const queryClientNoAuth = new QueryClient()
  const noAuth = promotionRoutes.some((validPath) => pathname.includes(validPath))
  const isFindALead = pathname.includes("/find-a-lead")

  if (noAuth) {
    return (
      <AxiosProviderNoAuth>
        <QueryClientProvider client={queryClientNoAuth}>
          <Init />
          <HotJar />
          <PromoContext.Provider value={true}>
            <Box display="flex" minHeight="100vh" bgcolor="neutral.main">
              <PromoNav isFindALead={isFindALead} />
              <Routes />
            </Box>
          </PromoContext.Provider>
        </QueryClientProvider>
      </AxiosProviderNoAuth>
    )
  } else {
    return (
      <QueryClientProvider client={queryClient(axiosInstance)}>
        <Init />
        <HotJar />
        <Box display="flex" minHeight="100vh" bgcolor="neutral.main">
          <Nav />
          <Stack padding={2} flex={1} minWidth="0">
            <Toolbar />
            <Stack flex={1}>
              <AdminNav />
              <Routes />
            </Stack>
          </Stack>
        </Box>
        <Footer />
      </QueryClientProvider>
    )
  }
}

export default App
