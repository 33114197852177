import { FC, useMemo } from "react"
import { DataGrid } from "@mui/x-data-grid"
import { useFetchProxies } from "@src/api/admin/content-acquisition/proxies"
import { GridColDef } from "@mui/x-data-grid/models/colDef"
import { theme } from "@src/theme"
import { useFetchContentAcquisitionJobs } from "@src/api/admin/content-acquisition/jobs"
import { fetchYesterdaysJobsParams } from "@src/interfaces/content-acquisition/query/jobs"
import { Job } from "@src/interfaces/content-acquisition/responseObjectInterfaces"
import { EnvironmentType, intTypeToStringMapping } from "@src/interfaces/content-acquisition/proxyEnvironmentTypes"

export const ProxyTable: FC = () => {
  const { data: proxies, isLoading: proxiesAreLoading } = useFetchProxies()
  const { data: jobs, isLoading: jobsAreLoading } = useFetchContentAcquisitionJobs(fetchYesterdaysJobsParams)

  const proxySuccessTotal = (jobs: Job[]) => {
    const totals = jobs.reduce(
      (acc, job) => {
        const squidId = job?.squid_id
        if (!squidId) return acc
        if (!acc[squidId]) acc[squidId] = { total: 0, success: 0 }

        acc[squidId].total++
        if (job.success) acc[squidId].success++

        return acc
      },
      {} as { [key: string]: { total: number; success: number } },
    )

    return totals
  }

  const proxyRows = useMemo(() => {
    if (!proxies || !jobs) return []
    if (!jobs) return proxies

    const successTotals = proxySuccessTotal(jobs)
    return proxies.map((squid) => {
      if (!successTotals[squid.id]?.success) return squid
      const success = successTotals[squid.id].success
      const total = successTotals[squid.id].total
      const percentage = total === 0 ? 0 : success / total
      return { ...squid, ...{ successRate: percentage, totalCount: total, successCount: success } }
    })
  }, [proxies, jobs])

  const proxyColumns: GridColDef[] = [
    {
      field: "id",
      flex: 0.1,
      headerName: "ID",
      type: "number",
    },
    { field: "name", flex: 1, headerName: "Name" },
    {
      field: "internal_ip",
      flex: 1,
      description: "Used by the Proxy Provider Downloader.",
      headerName: "Internal Ip",
    },
    {
      field: "external_ip",
      flex: 1,
      description: "Unique, visible identifier shown to target domains.",
      headerName: "External Ip",
    },
    { field: "zone", flex: 0.5, headerName: "Zone" },
    {
      field: "provider",
      flex: 1,
      description: "Host provider for proxy",
      headerName: "Provider",
    },
    {
      field: "suspended_until",
      headerName: "Suspended Until",
      valueGetter: (value) => new Date(value as string),
      renderCell: (params) => new Date(params.value as string).toLocaleString(),
      type: "dateTime",
      flex: 1,
    },
    { field: "disabled", type: "boolean", flex: 0.75, headerName: "Disabled" },
    {
      field: "environment_type",
      flex: 1,
      headerName: "Environment Type",
      type: "string",
      renderCell: (params) => intTypeToStringMapping[params.row.environment_type as EnvironmentType] || "Unknown",
    },
    {
      field: "successRate",
      flex: 1,
      headerName: "Success Percentage",
      valueGetter: (value) => {
        if (!value) return 100
        return value * 100
      },
      valueFormatter: (value) => {
        return `${(value as number).toFixed(2)}%`
      },
    },
    { field: "successCount", flex: 1, headerName: "Success Count" },
    { field: "totalCount", flex: 1, headerName: "Total Count" },
  ]

  return (
    <DataGrid
      autoHeight
      {...proxyRows}
      initialState={{
        columns: {
          columnVisibilityModel: {
            provider: false,
          },
        },
        pagination: { paginationModel: { pageSize: 25 } },
        sorting: {
          sortModel: [{ field: "successRate", sort: "asc" }],
        },
      }}
      sx={{
        '.MuiDataGrid-booleanCell[data-value="true"]': {
          color: theme.palette.success.main,
        },
        '.MuiDataGrid-booleanCell[data-value="false"]': {
          color: theme.palette.error.main,
        },
      }}
      density="compact"
      loading={proxiesAreLoading || jobsAreLoading}
      rows={proxyRows || []}
      columns={proxyColumns.map((column) => ({ ...column, headerClassName: "super-app-theme--header" }))}
    />
  )
}
