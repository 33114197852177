import { Info } from "@mui/icons-material"
import {
  Box,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Switch,
  TextField,
  Tooltip,
} from "@mui/material"
import { useFetchIndustries } from "@src/api/industries"
import { AddRemoveFormComponent } from "@src/components/shared/AddRemoveFormComponent"
import { useToast } from "@src/components/shared/ToastHook"
import { GeneralStepProps } from "@src/interfaces/newUserOrgForm"
import { CreateUserData } from "@src/interfaces/user"
import { CreateUserOrganization } from "@src/interfaces/userOrganization"
import { FC, useState } from "react"
import { Divider } from "rsuite"
import { DialogActionsContainer } from "../NewUserOrganizationWizardGeneralContent"
import { useFetchUserOrganizations } from "@src/api/admin/user_organizations"
import Loading from "@src/components/shared/Loading"

export const GeneralUserOrgFormFields: FC<GeneralStepProps> = ({
  newUserOrganization,
  setNewUserOrganization,
  setStep,
  handleOnClose,
}) => {
  const { data: industries } = useFetchIndustries()
  const { data: activeOrgs, isLoading } = useFetchUserOrganizations()
  const [localNewUserOrganization, setLocalNewUserOrganization] =
    useState<Partial<CreateUserOrganization>>(newUserOrganization)
  const setToast = useToast()

  const isUniqueName = () => {
    if (!localNewUserOrganization.name) {
      return false
    }
    return !activeOrgs?.find((org) => org.name.toLowerCase() === localNewUserOrganization.name?.toLowerCase())
  }

  const checkIfCanMoveOn = () => {
    if (!localNewUserOrganization.name || !(localNewUserOrganization.name.trim().length > 0) || !isUniqueName()) {
      return false
    }
    return true
  }

  const handleIndustrySelection = (event: SelectChangeEvent) => {
    if (!event.target.value) {
      setLocalNewUserOrganization({ ...localNewUserOrganization, industry_id: undefined, industry: undefined })
      return
    }
    const industry = industries?.find((industry) => industry.id === parseInt(event.target.value))
    if (industry) {
      setLocalNewUserOrganization({ ...localNewUserOrganization, industry_id: industry.id, industry: industry.name })
    } else {
      setToast("Industry not found")
    }
  }

  const handleCompanyNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // We also want to set the support user when the company name changes
    const companyName = event.target.value
    const supportUser: CreateUserData = {
      email: "support+" + companyName.toLowerCase().replace(/ /g, "_").trim() + "@cloverleaf.ai",
      requires_signals_notifications: false,
      requires_account_summary_notifications: false,
      role_ids: [1],
      credit_limit: 100,
    }
    // Grab the current user list and find/replace the current support user
    const currentUserList = localNewUserOrganization.user_list || []
    const supportUserIndex = currentUserList.findIndex((user) => user.email.startsWith("support+"))
    if (supportUserIndex >= 0) {
      currentUserList[supportUserIndex] = supportUser
    } else {
      currentUserList.push(supportUser)
    }
    setLocalNewUserOrganization({ ...localNewUserOrganization, name: companyName, user_list: currentUserList })
  }

  const handleClickNext = () => {
    setNewUserOrganization(localNewUserOrganization)
    setStep((prevVal) => prevVal + 1)
  }

  return (
    <>
      {isLoading ? (
        <Loading useCloverleafIcon />
      ) : (
        <>
          <Grid item>
            <FormControl required fullWidth>
              <TextField
                size="small"
                label="Name"
                required
                value={localNewUserOrganization.name || ""}
                onChange={handleCompanyNameChange}
                fullWidth
                helperText={isUniqueName() ? "" : "Name must be unique"}
              />
            </FormControl>
          </Grid>
          <Grid item>
            <FormControl fullWidth>
              <InputLabel>Industry</InputLabel>
              <Select
                value={localNewUserOrganization.industry_id?.toString() || undefined}
                label="Industry"
                onChange={handleIndustrySelection}
                fullWidth
              >
                <MenuItem value={undefined}>Select an industry</MenuItem>
                {industries &&
                  industries.map((industry) => (
                    <MenuItem key={industry.name} value={industry.id}>
                      {industry.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item>
            <FormControl fullWidth>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      checked={localNewUserOrganization.salesforce_enabled ?? false}
                      onChange={(_, checked) =>
                        setLocalNewUserOrganization({ ...localNewUserOrganization, salesforce_enabled: checked })
                      }
                    />
                  }
                  label="Salesforce Enabled"
                />
                <Box display={"flex"} flexDirection="row">
                  <FormControlLabel
                    control={
                      <Switch
                        checked={localNewUserOrganization.briefings_enabled ?? false}
                        onChange={(_, checked) =>
                          setLocalNewUserOrganization({ ...localNewUserOrganization, briefings_enabled: checked })
                        }
                      />
                    }
                    label="Briefings Enabled"
                  />
                  {localNewUserOrganization.briefings_enabled && (
                    <TextField
                      size="small"
                      label="Total Credits"
                      type="number"
                      sx={{ marginLeft: 2 }}
                      value={localNewUserOrganization.total_credits || ""}
                      onChange={(event) =>
                        setLocalNewUserOrganization({
                          ...localNewUserOrganization,
                          total_credits: parseInt(event.target.value),
                        })
                      }
                    />
                  )}
                </Box>
                <FormControlLabel
                  control={
                    <Switch
                      checked={localNewUserOrganization.speaker_identification_enabled ?? false}
                      onChange={(_, checked) =>
                        setLocalNewUserOrganization({
                          ...localNewUserOrganization,
                          speaker_identification_enabled: checked,
                        })
                      }
                    />
                  }
                  label="Speaker Identification Enabled"
                />
                <FormControlLabel
                  control={
                    <Switch
                      checked={localNewUserOrganization.opportunities_enabled ?? false}
                      onChange={(_, checked) =>
                        setLocalNewUserOrganization({
                          ...localNewUserOrganization,
                          opportunities_enabled: checked,
                        })
                      }
                    />
                  }
                  label="Opportunities Enabled"
                />
              </FormGroup>
            </FormControl>
          </Grid>
          <Divider />
          <Grid item>
            <Box display="flex" flexDirection="row" alignItems={"center"}>
              <FormControlLabel
                control={
                  <Switch
                    checked={localNewUserOrganization.default_user_configurations !== undefined}
                    onChange={(_event, checked) => {
                      checked
                        ? setLocalNewUserOrganization((prevVal) => {
                            return { ...prevVal, default_user_configurations: { competitors: [], goals: [] } }
                          })
                        : setLocalNewUserOrganization((prevVal) => {
                            return { ...prevVal, default_user_configurations: undefined }
                          })
                    }}
                  />
                }
                label="Set Default User Info"
              />
              <Tooltip title="Will set the personalized data for each user added during the next steps">
                <Info />
              </Tooltip>
            </Box>
            {localNewUserOrganization.default_user_configurations && (
              <Stack>
                <AddRemoveFormComponent
                  fieldName="Focus Area"
                  data={localNewUserOrganization.default_user_configurations?.goals || []}
                  handleChange={(data) =>
                    setLocalNewUserOrganization((prevValue) => ({
                      ...prevValue,
                      default_user_configurations: { ...prevValue.default_user_configurations, goals: data },
                    }))
                  }
                  textFieldProps={{ multiline: true }}
                />
                <AddRemoveFormComponent
                  fieldName="Competitor"
                  data={localNewUserOrganization.default_user_configurations?.competitors || []}
                  handleChange={(data) =>
                    setLocalNewUserOrganization((prevValue) => ({
                      ...prevValue,
                      default_user_configurations: { ...prevValue.default_user_configurations, competitors: data },
                    }))
                  }
                  textFieldProps={{ multiline: true, fullWidth: true }}
                />
              </Stack>
            )}
          </Grid>
          <DialogActionsContainer
            handleOnClose={handleOnClose}
            handleOnNext={checkIfCanMoveOn() ? handleClickNext : undefined}
          />
        </>
      )}
    </>
  )
}
