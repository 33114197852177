import { FC, useEffect, useState } from "react"
import { TextField, Stack, Button, Dialog, DialogTitle, DialogContent, DialogActions } from "@mui/material"
import { useFetchIndustries, useCreateIndustry, useRestoreIndustry, useDeleteIndustry } from "@api/admin/industries"
import { DataTable } from "../../shared/DataTable"
import { AugmentedStatus } from "../../shared/LoadingEllipsis"
import { SearchTextField } from "../../shared/SearchTextField"
import { RecommendedCampaigns } from "./RecommendedCampaigns"
import { CreateFolderModal } from "../../Bookmarks/CreateFolderModal"
import { IndustryBookmarkFolder } from "./IndustryBookmarkFolder"
import Loading from "../../shared/Loading"
import { AssociatedUser } from "./AssociatedUser"

export const Industries: FC = () => {
  const { data: industries, status, isLoading: indexLoading } = useFetchIndustries()
  const [isLoading, setIsLoading] = useState(false)
  const { mutate: restoreIndustry } = useRestoreIndustry()
  const { mutate: deleteIndustry } = useDeleteIndustry()
  const [search, setSearch] = useState("")
  const [name, setName] = useState("")
  const [open, setOpen] = useState(false)

  const close = () => {
    setOpen(false)
    setName("")
  }

  const { mutate: createIndustry, isLoading: createIsLoading } = useCreateIndustry(close)

  useEffect(() => {
    setIsLoading(createIsLoading || indexLoading)
  }, [createIsLoading, indexLoading])

  if (isLoading) {
    return <Loading useCloverleafIcon />
  }

  return (
    <>
      <Dialog open={open} onClose={close} fullWidth>
        <DialogTitle>Create Industry</DialogTitle>
        <DialogContent sx={{ overflowY: "unset" }}>
          <TextField label="Industry Name" fullWidth value={name} onChange={(event) => setName(event.target.value)} />
        </DialogContent>
        <DialogActions>
          <Button onClick={close}>Close</Button>
          <Button
            onClick={() => {
              createIndustry({ name })
              setName("")
            }}
            disabled={!name}
          >
            {createIsLoading ? "Loading" : "Create Industry"}
          </Button>
        </DialogActions>
      </Dialog>
      <Stack spacing={1} marginTop={1}>
        <Button variant="outlined" onClick={() => setOpen(true)} sx={{ alignSelf: "flex-start" }}>
          Create Industry
        </Button>
        <SearchTextField
          label="Search Industries"
          size="small"
          sx={{ width: "33.333%" }}
          search={search}
          setSearch={setSearch}
        />
      </Stack>
      {industries ? (
        <DataTable
          data={industries
            .filter(({ name }) => name.toLowerCase().includes(search))
            .map((industry) => {
              const industryCopy = {
                ...industry,
                deleteAction: (
                  <Stack direction="row" spacing={1}>
                    {industry.discarded_at && (
                      <Button variant="contained" onClick={() => restoreIndustry(industry.id)}>
                        Restore
                      </Button>
                    )}
                    <Button variant="outlined" color="error" onClick={() => deleteIndustry(industry.id)}>
                      {industry.discarded_at ? "Delete" : "Discard"}
                    </Button>
                  </Stack>
                ),
                recommendedCampaigns: <RecommendedCampaigns industry={industry} />,
                industryBookmark: <IndustryBookmarkFolder industry={industry} />,
                associatedUser: <AssociatedUser industry={industry} />,
              }

              return industryCopy
            })}
          columns={[
            { id: "name", label: "Name" },
            { id: "discarded_at", label: "Discarded At" },
            { id: "deleteAction", label: "" },
            { id: "recommendedCampaigns", label: "" },
            { id: "industryBookmark", label: "" },
            { id: "associatedUser", label: "Assigned User" },
          ]}
          columnKey="id"
        />
      ) : (
        <AugmentedStatus status={status} />
      )}
      <CreateFolderModal />
    </>
  )
}
