import { FC, useState } from "react"
import { Divider } from "@mui/material"
import { Confirm } from "../components/shared/Confirm"
import { useNewOrganization } from "../api/organizations"
import { Redirect, Route, Switch, useLocation, useRouteMatch } from "react-router-dom"
import { SupportContainer } from "../components/Admin/Other/SupportContainer"
import { UserOrganizationList } from "../components/Admin/UserOrganizations/UserOrganizationList"
import { UserOrganizationPage } from "../components/Admin/UserOrganizations/UserOrganizationPage"
import { GeographyAdminContainer } from "../components/Admin/Geographies/GeographyAdminContainer"
import { UserAdminContainer } from "../components/Admin/Users/UserAdminContainer"
import { Helmet } from "react-helmet"
import { RoleAdminContainer } from "../components/Admin/Roles/RoleAdminContainer"
import { OrganizationAdminContainer } from "../components/Admin/Organizations/OrganizationAdminContainer"
import { Wizard } from "../components/Admin/Organizations/Wizard"
import { MeetingsAdminContainer } from "../components/Admin/Meetings/MeetingsAdminContainer"
import { Industries } from "../components/Admin/Industries"
import AdminHitsTable from "../components/Admin/HitsTable"
import { TrialContainer } from "../components/Admin/TrialUsers/TrialContainer"
import { ChannelTypesContainer } from "../components/Admin/ChannelTypes/ChannelTypesContainer"
import { MonitorsContainer } from "../components/Admin/Monitors/MonitorsContainer"
import { Prompts } from "../components/Admin/Prompts"
import { ProxyContainer } from "../components/Admin/ContentAcquisition/ProxyContainer"
import { FlagsContainer } from "../components/Admin/Flags/FlagsContainer"
import { Uat } from "../components/Admin/Uat"
import { Persons } from "../components/Admin/Persons"
import { OrganizationDetailPage } from "./OrganizationDetailPage"
import { useFetchCurrentUser, useIsContractor, useIsSuperAdmin } from "../api/users"

export const AdminPage: FC = () => {
  const { pathname } = useLocation()
  const [dialogOpen, setDialogOpen] = useState(false)
  const { url } = useRouteMatch()
  const { data: isSuperAdmin } = useIsSuperAdmin()
  const { data: isContractor } = useIsContractor()
  const { data: currentUser } = useFetchCurrentUser()

  const { mutate: newOrganization } = useNewOrganization()

  const routes = [
    { path: `${url}/channels`, component: OrganizationAdminContainer, exact: true },
    { path: `${url}/channels/wizard`, component: Wizard },
    { path: `${url}/channel_types`, component: ChannelTypesContainer },
    { path: `${url}/user_organizations`, component: UserOrganizationList, exact: true },
    { path: `${url}/user_organizations/:userOrganizationId`, component: UserOrganizationPage },
    { path: `${url}/geographies`, component: GeographyAdminContainer },
    { path: `${url}/users`, component: UserAdminContainer },
    { path: `${url}/meetings`, component: MeetingsAdminContainer },
    { path: `${url}/roles`, component: RoleAdminContainer },
    { path: `${url}/industries`, component: Industries },
    { path: `${url}/monitors`, component: MonitorsContainer },
    { path: `${url}/prompts`, component: Prompts },
    { path: `${url}/persons`, component: Persons },
    { path: `${url}/other`, component: SupportContainer },
    { path: `${url}/trial_users`, component: TrialContainer },
    { path: `${url}/hits_table`, component: AdminHitsTable },
    { path: `${url}/proxies`, component: ProxyContainer },
    { path: `${url}/flags`, component: FlagsContainer },
    { path: `${url}/uat`, component: Uat },
    { path: `${url}/organizations/:organizationId`, component: OrganizationDetailPage },
  ].filter(
    ({ path }) =>
      isSuperAdmin ||
      isContractor ||
      currentUser?.available_routes?.some((availableRoute) => {
        const splitPath = path.slice(1).split("/")
        const filteredPath = splitPath.filter((pathSegment) => pathSegment.charAt(0) !== ":")
        const baseRoute = availableRoute.split("/").slice(0, 2).join("/")
        return baseRoute === filteredPath.join("/")
      }),
  )

  if ((routes.length && pathname.split("/").length === 2) || pathname.split("/")[2] === "") {
    return <Redirect to={routes[0].path} />
  }

  return (
    <>
      <Helmet>
        <title>Cloverleaf.AI | Admin</title>
      </Helmet>
      <Divider />
      <Switch>
        {routes.map(({ path, component, exact }) => (
          <Route key={path} path={path} component={component} exact={exact} />
        ))}
      </Switch>
      <Confirm
        onClose={() => setDialogOpen(false)}
        title="New Channel"
        body="Create a new channel?"
        onSucceed={newOrganization}
        open={dialogOpen}
      />
    </>
  )
}
