import { FC } from "react"
import { Stack, Card, CardHeader, CardContent, Chip, Typography, Link as MuiLink, Skeleton } from "@mui/material"
import { Link } from "react-router-dom"
import { useFetchOpportunityCampaigns } from "@src/api/opportunityCampaigns"
import { OpportunityCampaign } from "@src/interfaces/opportunityCampaign"
import { useFetchNaicsCodesMapByCode } from "@src/api/naicsCodes"
import { useFetchOrganizationsMap } from "@functions/organizations"
import { useFetchGeographiesMap } from "@src/api/geographies"

const OpportunityCampaignCard: FC<{ opportunityCampaign: OpportunityCampaign }> = ({
  opportunityCampaign: {
    id,
    name,
    data: { terms, naics_code, organizations, cities, counties, states },
  },
}) => {
  const { data: naicsCodesMap } = useFetchNaicsCodesMapByCode()
  const filterTexts: string[] = []
  if (naics_code?.length) {
    filterTexts.push(
      naics_code
        .map((code) => {
          const naicsCode = naicsCodesMap?.get(code)
          return `${naicsCode?.code}: ${naicsCode?.title}`
        })
        .join(", "),
    )
  }

  const { data: organizationsMap } = useFetchOrganizationsMap()
  if (organizations?.length) {
    filterTexts.push(organizations?.map((id) => organizationsMap?.get(id)?.name).join(", "))
  }

  const { data: geographiesMap } = useFetchGeographiesMap()
  if (cities?.length) {
    filterTexts.push(cities?.map((id) => geographiesMap?.cities.get(id)?.name).join(", "))
  }
  if (counties?.length) {
    filterTexts.push(counties?.map((id) => geographiesMap?.counties.get(id)?.name).join(", "))
  }
  if (states?.length) {
    filterTexts.push(states?.map((id) => geographiesMap?.states.get(id)?.name).join(", "))
  }

  return (
    <Card>
      <CardHeader
        title={
          <MuiLink component={Link} to={`/opportunities?campaign=${id}`}>
            {name}
          </MuiLink>
        }
        sx={{ paddingBottom: 0 }}
      />
      <CardContent sx={{ paddingTop: 1 }}>
        <Stack direction="row" gap={1}>
          {terms?.map((term) => <Chip key={term} label={term} />)}
        </Stack>
        <Typography>{filterTexts.join(" in ")}</Typography>
      </CardContent>
    </Card>
  )
}

const OpportunityCampaignCardSkeleton: FC = () => (
  <Card>
    <CardHeader title={<Skeleton width="24ch" />} sx={{ paddingBottom: 0 }} />
    <CardContent sx={{ paddingTop: 1 }}>
      <Stack direction="row" gap={1}>
        <Chip label={<Skeleton width="6ch" />} />
        <Chip label={<Skeleton width="6ch" />} />
        <Chip label={<Skeleton width="6ch" />} />
      </Stack>
      <Typography>
        <Skeleton sx={{ maxWidth: "64ch" }} />
      </Typography>
    </CardContent>
  </Card>
)

export const OpportunityCampaigns: FC = () => {
  const { data: opportunityCampaigns } = useFetchOpportunityCampaigns()
  return (
    <Stack gap={1} marginTop={1}>
      {opportunityCampaigns ? (
        opportunityCampaigns?.map((opportunityCampaign) => (
          <OpportunityCampaignCard opportunityCampaign={opportunityCampaign} />
        ))
      ) : (
        <>
          <OpportunityCampaignCardSkeleton />
          <OpportunityCampaignCardSkeleton />
          <OpportunityCampaignCardSkeleton />
        </>
      )}
    </Stack>
  )
}
