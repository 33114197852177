import { FC, useState, useMemo } from "react"
import { useDeleteFlags, useGetAllFeatureFlags, useGetAllFeatureFlagTypes } from "../../../api/admin/flags"
import { DataTable as Table } from "../../shared/DataTable"
import { columns } from "../../../tableColumns/flagColumns"
import { Button, Menu, MenuItem, Stack } from "@mui/material"
import { useOpenModalKey } from "../../shared/OpenModalHook"
import { CreateUpdateModal, createUpdateKey } from "./CreateUpdateModal"

export const FlagsContainer: FC = () => {
  const { data: flagData } = useGetAllFeatureFlags()
  // preload flag types
  useGetAllFeatureFlagTypes()
  const [selected, setSelected] = useState([])
  const clearSelected = () => setSelected([])
  const { mutate: deleteFlags } = useDeleteFlags(clearSelected)
  const openCreate = useOpenModalKey(createUpdateKey)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleOpen = (open: () => void) => {
    handleClose()
    open()
  }

  const selectedFlag = useMemo(() => flagData?.find((flag) => flag.id === selected[0]), [flagData, selected])

  return (
    <Stack>
      <Button size="large" variant="contained" onClick={handleClick} sx={{ width: 150, margin: 1 }}>
        Actions
      </Button>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem disabled={selected.length > 0} onClick={() => handleOpen(openCreate)}>
          Create
        </MenuItem>
        <MenuItem disabled={selected.length < 1} onClick={() => handleOpen(() => deleteFlags(selected))}>
          Delete
        </MenuItem>
        <MenuItem disabled={selected.length !== 1} onClick={() => handleOpen(openCreate)}>
          Update
        </MenuItem>
      </Menu>
      <Table data={flagData} columns={columns} columnKey="id" selected={selected} setSelected={setSelected} />
      <CreateUpdateModal featureFlag={selectedFlag} onSuccess={clearSelected} />
    </Stack>
  )
}
