import {
  Box,
  Stack,
  Button,
  Dialog,
  DialogTitle,
  Divider,
  DialogContent,
  Typography,
  Autocomplete,
  TextField,
} from "@mui/material"
import { useUpdateIndustry } from "@src/api/admin/industries"
import { FetchedUsers, useFetchMyUsers } from "@src/api/admin/users"
import Loading from "@src/components/shared/Loading"
import { useToast } from "@src/components/shared/ToastHook"
import { FetchedIndustry } from "@src/interfaces/industry"
import { FC, useEffect, useState } from "react"

export const AssociatedUser: FC<{ industry: FetchedIndustry }> = ({ industry }) => {
  const [openDialog, setOpenDialog] = useState(false)
  const setToast = useToast()

  const handleSuccess = (msg: string) => {
    setToast(msg)
  }
  const { mutate: updateIndustry, isLoading: updateIndustryIsLoading } = useUpdateIndustry(handleSuccess)
  const [isLoading, setIsLoading] = useState(false)
  const [selectedUser, setSelectedUser] = useState<FetchedUsers>()

  const { data: userOptions, isLoading: userOptionsIsLoading } = useFetchMyUsers()

  useEffect(() => {
    setIsLoading(updateIndustryIsLoading)
  }, [updateIndustryIsLoading])

  const handleCloseDialog = () => {
    setSelectedUser(undefined)
    setOpenDialog(false)
  }

  const updateAssignedUser = () => {
    if (!selectedUser) {
      setToast("Please select a user", undefined, undefined, "error")
      return
    }
    updateIndustry({ id: industry.id, user_id: selectedUser.id })
  }

  return (
    <Box width={"100%"}>
      {industry.user ? (
        <Button variant="outlined" onClick={() => setOpenDialog(true)}>
          {industry.user.email}
        </Button>
      ) : (
        <Button variant="outlined" onClick={() => setOpenDialog(true)}>
          No Assigned User
        </Button>
      )}
      <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth="sm" fullWidth>
        <DialogTitle marginTop={2}>Assigned User</DialogTitle>
        <Divider />
        {isLoading || !industry ? (
          <Box padding={2} minWidth={"500px"}>
            <Loading useCloverleafIcon />
          </Box>
        ) : (
          <DialogContent>
            {industry.user ? (
              <Stack spacing={2}>
                <Typography variant="h6">Assigned User</Typography>
                <Typography>{industry.user.email}</Typography>
              </Stack>
            ) : (
              <Typography variant="h6">No Assigned User</Typography>
            )}

            <Divider sx={{ paddingBottom: 2 }} />
            {/* Form to update the assigned user */}
            <Stack>
              {userOptionsIsLoading || userOptions === undefined ? (
                <Loading />
              ) : (
                <Autocomplete
                  size="small"
                  fullWidth
                  options={userOptions.users}
                  getOptionLabel={(user) => user.email}
                  renderOption={(props, user) => (
                    <li {...props}>
                      <Stack direction="row" spacing={1} justifyContent={"space-between"} width={"100%"}>
                        {user.email}
                      </Stack>
                    </li>
                  )}
                  sx={{ marginTop: 2 }}
                  onChange={(e, value) => {
                    if (value) {
                      setSelectedUser(value)
                    }
                  }}
                  renderInput={(params) => <TextField {...params} label="Select User" />}
                />
              )}
              <Button onClick={updateAssignedUser} disabled={isLoading || selectedUser === undefined}>
                {selectedUser?.email ? `Update Assigned User to ${selectedUser.email}` : "Update Assigned User"}
              </Button>
            </Stack>
          </DialogContent>
        )}
      </Dialog>
    </Box>
  )
}
