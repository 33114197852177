import { useQuery } from "react-query"
import { useAuthAxios } from "./axiosInstance"
import { Opportunity } from "@src/interfaces/opportunity"
import { NaicsCode } from "@src/interfaces/naicsCode"
import { useIsOpportunitiesEnabled } from "./users"

const prefix = "/opportunities"

export const urlParamsToOpportunityQueryObject = (params: URLSearchParams) => ({
  states: params.getAll("states").map((state) => parseInt(state)),
  cities: params.getAll("cities").map((city) => parseInt(city)),
  counties: params.getAll("counties").map((county) => parseInt(county)),
  organizations: params.getAll("organizations").map((organization) => parseInt(organization)),
  naics_code: params.getAll("naics_code"),
  channel_types: params.getAll("channel_types").map((channelType) => parseInt(channelType)),
  city_population: params.getAll("city_population").map((cityPopulation) => parseInt(cityPopulation)) as [
    number,
    number,
  ],
  county_population: params.getAll("county_population").map((countyPopulation) => parseInt(countyPopulation)) as [
    number,
    number,
  ],
  city_income: params.getAll("city_income").map((cityIncome) => parseInt(cityIncome)) as [number, number],
  county_income: params.getAll("county_income").map((countyIncome) => parseInt(countyIncome)) as [number, number],
  terms: params.getAll("terms"),
  sort: params.get("sort") || "",
  order: params.get("order") || "",
  page: parseInt(params.get("page") ?? "0"),
  index: parseInt(params.get("index") ?? "0"),
  count: parseInt(params.get("count") ?? "25"),
})

export interface OpportunityIndexResult extends Opportunity {
  naics_code: NaicsCode
  channel_id: number
  channel_name: string
  state_name: string
  county_name?: string
  city_name?: string
  published_at: string
}

export const useFetchOpportunities = (params?: URLSearchParams) => {
  const axiosInstance = useAuthAxios()
  const { data: isOpportunitiesEnabled } = useIsOpportunitiesEnabled()

  return useQuery(
    ["opportunities", params && urlParamsToOpportunityQueryObject(params)],
    async () => {
      let url = prefix
      if (params) {
        const rubyParamsCollection: string[][] = []
        params.getAll("states").forEach((state) => rubyParamsCollection.push(["states[]", state]))
        params.getAll("cities").forEach((city) => rubyParamsCollection.push(["cities[]", city]))
        params.getAll("counties").forEach((county) => rubyParamsCollection.push(["counties[]", county]))
        params
          .getAll("organizations")
          .forEach((organization) => rubyParamsCollection.push(["organizations[]", organization]))
        params.getAll("naics_code").forEach((naicsCode) => rubyParamsCollection.push(["naics_code[]", naicsCode]))
        params.getAll("terms").forEach((term) => rubyParamsCollection.push(["terms[]", term]))
        const sort = params.get("sort")
        if (sort) {
          rubyParamsCollection.push(["sort", sort])
        }
        const order = params.get("order")
        if (order) {
          rubyParamsCollection.push(["order", order])
        }
        rubyParamsCollection.push([
          "index",
          (params.get("index") ?? parseInt(params.get("page") || "0") * 25).toString(),
        ])
        rubyParamsCollection.push(["count", parseInt(params.get("count") ?? "25").toString()])

        url += `?${new URLSearchParams(rubyParamsCollection).toString()}`
      }
      const response = await (await axiosInstance).get<{ opportunities: OpportunityIndexResult[]; total: number }>(url)

      return response.data
    },
    {
      enabled: Boolean(isOpportunitiesEnabled),
    },
  )
}
