import { FC, useEffect, useMemo, useState } from "react"
import { FormControl, InputLabel, OutlinedInput, InputAdornment, IconButton, Paper, Stack, Link } from "@mui/material"
import { useOpenModalKey } from "../../shared/OpenModalHook"
import { CreatePersonModal, CREATE_PERSON_MODAL_KEY } from "./createPersonModal"
import { Layout } from "../../shared/Layout"
import AddIcon from "@mui/icons-material/Add"
import { useFetchPersons, useUpdatePerson } from "../../../api/admin/persons"
import SearchIcon from "@mui/icons-material/Search"
import { useLocation } from "react-router-dom"
import useSetUrlParamState from "../../shared/UseSetUrlParamState"
import {
  DataGrid,
  GridColDef,
  GridRowsProp,
  GridActionsCellItem,
  GridPaginationModel,
  GridSortModel,
  GridSortDirection,
  GridRowParams,
  GridValidRowModel,
} from "@mui/x-data-grid"
import { formatDateTime } from "@src/components/shared/DataTable"
import { Clear, MenuOpenOutlined } from "@mui/icons-material"
import { VOICE_MODAL_KEY, VoiceModal } from "@src/components/shared/VoicesModal"
import { PersonVoicesTranscripts } from "@src/interfaces/person"
import { useToast } from "@src/components/shared/ToastHook"
import { isEqual } from "lodash"

export const Persons: FC = () => {
  const open = useOpenModalKey(CREATE_PERSON_MODAL_KEY)
  const openVoices = useOpenModalKey(VOICE_MODAL_KEY)
  const params = new URLSearchParams(useLocation().search)
  const { data, isLoading: searchIsLoading } = useFetchPersons(params, true)
  const toast = useToast()
  const { mutate: updatePerson, isLoading: personIsLoading } = useUpdatePerson(() =>
    toast("Person updated", undefined, undefined, "info"),
  )
  const [selectedPerson, setSelectedPerson] = useState<PersonVoicesTranscripts>()
  const [isLoading, setIsLoading] = useState(true)

  const filterParams = useMemo(
    () => ({
      name: params.get("name") || undefined,
      page: parseInt(params.get("page") || "0"),
      count: parseInt(params.get("count") || "25"),
      title: params.get("title") || undefined,
      organization: params.get("organization") || undefined,
      sort_by: params.get("sort_by"),
      sort_order: params.get("sort_order"),
    }),
    [params],
  )

  useEffect(() => {
    setIsLoading(searchIsLoading || personIsLoading)
  }, [searchIsLoading, personIsLoading])

  const [searchName, setSearchName] = useState<string>(filterParams.name || "")
  const [searchTitle, setSearchTitle] = useState<string>(filterParams.title || "")
  const [searchOrganization, setSearchOrganization] = useState<string>(filterParams.organization || "")

  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    pageSize: filterParams.count,
    page: filterParams.page,
  })

  const [sortingModel, setSortingModel] = useState<GridSortModel>(
    filterParams.sort_by
      ? [
          {
            field: filterParams.sort_by,
            sort: filterParams.sort_order ? (filterParams.sort_order as GridSortDirection) : "asc",
          },
        ]
      : [],
  )

  const hasChanges = ({
    id,
    name,
    title,
    organization,
  }: {
    id: number
    name: string
    title: string
    organization: string
  }) => {
    const originalRow = data?.persons.find((person) => person.id === id)
    if (!originalRow) {
      return false
    }
    const obj1 = { name: name, title: title, organization: organization }
    const obj2 = { name: originalRow.name, title: originalRow.title, organization: originalRow.organization }
    return !isEqual(obj1, obj2)
  }

  const handleOpenVoiceModal = (person: PersonVoicesTranscripts) => {
    setSelectedPerson(person)
    openVoices()
  }

  const handleUpdatePerson = (row: GridValidRowModel) => {
    updatePerson({
      id: row.id as number,
      name: row.name as string,
      title: row.title as string,
      organization: row.organization as string,
    })
  }

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "ID",
      flex: 1,
      renderCell(params) {
        return <Link href={`/persons/${params.row.id}`}>{params.row.id}</Link>
      },
    },
    { field: "name", headerName: "Name", flex: 3, editable: true },
    { field: "title", headerName: "Title", flex: 3, editable: true },
    { field: "organization", headerName: "Organization", flex: 4, editable: true },
    { field: "created_at", headerName: "Created At", flex: 2 },
    { field: "updated_at", headerName: "Updated At", flex: 2 },
    {
      field: "voices",
      headerName: "Voices",
      flex: 1,
      sortable: false,
      type: "actions",
      getActions: (params: GridRowParams) => [
        <GridActionsCellItem
          label="Voices"
          disabled={!params.row.voices || params.row.voices.length === 0}
          onClick={() => {
            handleOpenVoiceModal(params.row)
          }}
          icon={<MenuOpenOutlined />}
        />,
      ],
    },
    {
      field: "actions",
      type: "actions",
      getActions: ({ row }) => {
        return [
          <GridActionsCellItem
            label="Update"
            disabled={
              personIsLoading ||
              !hasChanges({ id: row.id, name: row.name, title: row.title, organization: row.organization })
            }
            onClick={() => handleUpdatePerson(row)}
            showInMenu={true}
          />,
        ]
      },
    },
  ]

  const [rows, setRows] = useState<GridRowsProp>()

  const setFilterParams = useSetUrlParamState<typeof filterParams>()

  useEffect(() => {
    if (isLoading) {
      return
    }
    if (data) {
      setRows(
        data.persons.map((person) => ({
          id: person.id,
          name: person.name,
          title: person.title,
          organization: person.organization,
          created_at: formatDateTime(person.created_at),
          updated_at: formatDateTime(person.updated_at),
          voices: person.voices,
        })),
      )
    }
  }, [data, isLoading])

  const handlePaginationChange = (paginationModel: GridPaginationModel) => {
    setPaginationModel(paginationModel)
    setFilterParams({
      ...filterParams,
      page: paginationModel.page,
      count: paginationModel.pageSize,
    })
  }

  const handleSortingChange = (sortingModel: GridSortModel) => {
    setSortingModel(sortingModel)
    setPaginationModel({ ...paginationModel, page: 0 })
    setFilterParams({
      ...filterParams,
      sort_by: sortingModel[0]?.field,
      sort_order: sortingModel[0]?.sort || "asc",
      page: 0,
    })
  }

  return (
    <Layout title="Persons" actions={[{ text: "Create", icon: <AddIcon />, action: open }]}>
      <Stack direction="row" spacing={2} sx={{ marginTop: 2 }} width={"100%"} alignContent={"center"}>
        <FormControl variant="outlined" fullWidth>
          <InputLabel>Name search</InputLabel>
          <OutlinedInput
            value={searchName}
            onChange={(event) => setSearchName(event.target.value)}
            onKeyPress={(event) => {
              if (event.key === "Enter") {
                setFilterParams({ ...filterParams, name: searchName, page: 0 })
              }
            }}
            endAdornment={
              <InputAdornment position="end">
                <IconButton onClick={() => setFilterParams({ ...filterParams, name: searchName, page: 0 })} edge="end">
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
        <FormControl variant="outlined" fullWidth>
          <InputLabel>Title search</InputLabel>
          <OutlinedInput
            value={searchTitle}
            onChange={(event) => setSearchTitle(event.target.value)}
            onKeyPress={(event) => {
              if (event.key === "Enter") {
                setFilterParams({ ...filterParams, title: searchTitle, page: 0 })
              }
            }}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setFilterParams({ ...filterParams, title: searchTitle, page: 0 })}
                  edge="end"
                >
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
        <FormControl variant="outlined" fullWidth>
          <InputLabel>Organization search</InputLabel>
          <OutlinedInput
            value={searchOrganization}
            onChange={(event) => setSearchOrganization(event.target.value)}
            onKeyPress={(event) => {
              if (event.key === "Enter") {
                setFilterParams({ ...filterParams, organization: searchOrganization, page: 0 })
              }
            }}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setFilterParams({ ...filterParams, organization: searchOrganization, page: 0 })}
                  edge="end"
                >
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
        {/* Clear form */}
        <IconButton
          title="Clear all search filters"
          onClick={() => {
            setSearchName("")
            setSearchTitle("")
            setSearchOrganization("")
            setFilterParams({ ...filterParams, name: undefined, title: undefined, organization: undefined, page: 0 })
          }}
        >
          <Clear />
        </IconButton>
      </Stack>
      <Paper sx={{ marginTop: 2 }}>
        <DataGrid
          rows={rows}
          columns={columns}
          loading={isLoading}
          sx={{
            ".MuiDataGrid-columnSeparator": {
              display: "none",
            },
            "&.MuiDataGrid-root": {
              border: "none",
            },
          }}
          pagination
          pageSizeOptions={[10, 25, 50, 100]}
          paginationMode="server"
          sortingMode="server"
          paginationModel={paginationModel}
          sortModel={sortingModel}
          autoHeight={true}
          onSortModelChange={handleSortingChange}
          onPaginationModelChange={handlePaginationChange}
          rowCount={data?.total ?? -1}
        />
      </Paper>
      <CreatePersonModal />
      <VoiceModal person={selectedPerson} />
    </Layout>
  )
}
