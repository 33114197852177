import { useQuery } from "react-query"
import { useAuthAxios } from "./axiosInstance"
import { Industry } from "../interfaces/industry"

const prefix = "/industries"

export const useFetchIndustries = () => {
  const axiosInstance = useAuthAxios()

  return useQuery<Industry[]>(["industries", { discarded_at: false }], async () => {
    const response = await (await axiosInstance).get(prefix)

    return response.data
  })
}
