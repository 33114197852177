import { useQuery } from "react-query"
import { useAuthAxios } from "./axiosInstance"
import { PublicBriefing } from "../interfaces/briefing"
import { useIsBriefingsEnabled } from "./users"

export const urlParamsToBriefingQueryObject = (params: URLSearchParams) => ({
  states: params.getAll("states").map((state) => parseInt(state)),
  cities: params.getAll("cities").map((city) => parseInt(city)),
  counties: params.getAll("counties").map((county) => parseInt(county)),
  organizations: params.getAll("organizations").map((organization) => parseInt(organization)),
  channel_types: params.getAll("channel_types").map((channelType) => parseInt(channelType)),
  meetings: params.getAll("meetings").map((meeting) => parseInt(meeting)),
  city_population: params.getAll("city_population").map((cityPopulation) => parseInt(cityPopulation)) as [
    number,
    number,
  ],
  county_population: params.getAll("county_population").map((countyPopulation) => parseInt(countyPopulation)) as [
    number,
    number,
  ],
  city_income: params.getAll("city_income").map((cityIncome) => parseInt(cityIncome)) as [number, number],
  county_income: params.getAll("county_income").map((countyIncome) => parseInt(countyIncome)) as [number, number],
  types: params.getAll("types"),
  users: params.getAll("users").map((user) => parseInt(user)),
  text: params.get("text") || "",
  sort: params.get("sort") || "",
  order: params.get("order") || "",
  page: parseInt(params.get("page") ?? "0"),
  index: parseInt(params.get("index") ?? "0"),
  count: parseInt(params.get("count") ?? "25"),
})

export const getBriefings = (params?: URLSearchParams) => {
  const axiosInstance = useAuthAxios()
  const { data: isBriefingsEnabled } = useIsBriefingsEnabled()

  return useQuery(
    ["briefings", params && urlParamsToBriefingQueryObject(params)],
    async () => {
      let url = "briefings"
      if (params) {
        const rubyParamsCollection: string[][] = []
        params.getAll("states").forEach((state) => rubyParamsCollection.push(["states[]", state]))
        params.getAll("cities").forEach((city) => rubyParamsCollection.push(["cities[]", city]))
        params.getAll("counties").forEach((county) => rubyParamsCollection.push(["counties[]", county]))
        params
          .getAll("organizations")
          .forEach((organization) => rubyParamsCollection.push(["organizations[]", organization]))
        params.getAll("meetings").forEach((meeting) => rubyParamsCollection.push(["meetings[]", meeting]))
        params.getAll("types").forEach((type) => rubyParamsCollection.push(["types[]", type]))
        params.getAll("users").forEach((user) => rubyParamsCollection.push(["users[]", user]))
        const searchText = params.get("text")
        if (searchText) {
          rubyParamsCollection.push(["text", searchText])
        }
        const sort = params.get("sort")
        if (sort) {
          rubyParamsCollection.push(["sort", sort])
        }
        const order = params.get("order")
        if (order) {
          rubyParamsCollection.push(["order", order])
        }
        rubyParamsCollection.push([
          "index",
          (params.get("index") ?? parseInt(params.get("page") || "0") * 25).toString(),
        ])
        rubyParamsCollection.push(["count", parseInt(params.get("count") ?? "25").toString()])

        url += `?${new URLSearchParams(rubyParamsCollection).toString()}`
      }
      const response = await (await axiosInstance).get<{ total: number; briefings: PublicBriefing[] }>(url)

      return response.data
    },
    {
      enabled: Boolean(isBriefingsEnabled),
    },
  )
}
