import { FC, useEffect, useState } from "react"
import { TextField, Button, Autocomplete } from "@mui/material"
import { useLocation } from "react-router-dom"
import { urlParamsToOpportunityQueryObject } from "../../api/opportunities"
import {
  useFetchOpportunityCampaignsMap,
  useCreateOpportunityCampaigns,
  useUpdateOpportunityCampaigns,
} from "@src/api/opportunityCampaigns"
import { ConfirmationModal } from "@src/components/shared/ConfirmationModal"
import { useOpenModalKey } from "../shared/OpenModalHook"
import { useToast } from "../shared/ToastHook"
import useSetUrlParamState from "../shared/UseSetUrlParamState"

const SAVE_NEW_OPPORTUNITY_CAMPAIGN_MODAL_KEY = "SAVE_NEW_OPPORTUNITY_CAMPAIGN"
const UPDATE_OPPORTUNITY_CAMPAIGN_MODAL_KEY = "UPDATE_OPPORTUNITY_CAMPAIGN"

export const CampaignFields: FC = () => {
  const paramsString = useLocation().search
  const params = new URLSearchParams(paramsString)

  const campaignIdString = params.get("campaign")
  const campaignId = campaignIdString ? parseInt(campaignIdString, 10) : undefined

  const urlParamState = urlParamsToOpportunityQueryObject(params)
  const setUrlParamState = useSetUrlParamState<typeof urlParamState & { campaign?: string }>()

  const { data: opportunityCampaigns } = useFetchOpportunityCampaignsMap()
  const toast = useToast()

  const { mutate: createOpportunityCampaign } = useCreateOpportunityCampaigns(() =>
    toast("Campaign successfully saved"),
  )
  const { mutate: updateOpportunityCampaign } = useUpdateOpportunityCampaigns(campaignId, () =>
    toast("Campaign successfully updated"),
  )

  const [saveCampaignName, setSaveCampaignName] = useState("")
  const openSaveNewOpportunityCampaignModal = useOpenModalKey(SAVE_NEW_OPPORTUNITY_CAMPAIGN_MODAL_KEY)
  const [updateCampaignName, setUpdateCampaignName] = useState("")
  const openUpdateOpportunityCampaignModal = useOpenModalKey(UPDATE_OPPORTUNITY_CAMPAIGN_MODAL_KEY)

  useEffect(() => {
    if (campaignId) {
      setUrlParamState({
        ...urlParamState,
        ...(opportunityCampaigns?.get(campaignId)?.data || {}),
        campaign: `${campaignId}`,
        page: 0,
      })
    }
  }, [campaignId])

  return (
    <>
      <Autocomplete
        sx={{ flex: "1" }}
        options={opportunityCampaigns ? Array.from(opportunityCampaigns.keys()) : []}
        value={campaignId}
        onChange={(_, newValue) => {
          if (newValue && newValue > 0) {
            setUrlParamState({
              ...urlParamState,
              campaign: `${newValue}`,
              page: 0,
            })
          }
        }}
        renderInput={(params) => <TextField {...params} label="Opportunity Campaigns" />}
        getOptionLabel={(id) => opportunityCampaigns?.get(id)?.name || ""}
      />
      <Button
        variant="outlined"
        onClick={() => {
          openUpdateOpportunityCampaignModal()
          if (campaignId) {
            setUpdateCampaignName(opportunityCampaigns?.get(campaignId)?.name || "")
          }
        }}
        disabled={!campaignId || !opportunityCampaigns?.get(campaignId)?.can_edit}
      >
        Update Campaign
      </Button>
      <ConfirmationModal
        title="Update Opportunity Campaign"
        body={
          <TextField
            label="Campaign Name"
            value={updateCampaignName}
            onChange={(event) => setUpdateCampaignName(event.target.value)}
            sx={{ width: "64ch", marginTop: 1 }}
            required
          />
        }
        modalKey={UPDATE_OPPORTUNITY_CAMPAIGN_MODAL_KEY}
        onConfirm={() => {
          updateOpportunityCampaign({ name: updateCampaignName, data: urlParamState })
          setUpdateCampaignName("")
        }}
        onCancel={() => setUpdateCampaignName("")}
      />
      <Button variant="outlined" onClick={openSaveNewOpportunityCampaignModal}>
        Save New Campaign
      </Button>
      <ConfirmationModal
        title="Save New Opportunity Campaign"
        body={
          <TextField
            label="Campaign Name"
            value={saveCampaignName}
            onChange={(event) => setSaveCampaignName(event.target.value)}
            sx={{ width: "64ch", marginTop: 1 }}
            required
          />
        }
        modalKey={SAVE_NEW_OPPORTUNITY_CAMPAIGN_MODAL_KEY}
        onConfirm={() => {
          createOpportunityCampaign({ name: saveCampaignName, data: urlParamState })
          setSaveCampaignName("")
        }}
        onCancel={() => setSaveCampaignName("")}
      />
    </>
  )
}
