import { useMutation, useQuery, useQueryClient } from "react-query"
import { useAuthAxios } from "./axiosInstance"
import { AxiosError } from "axios"
import { OpportunityCampaign } from "@src/interfaces/opportunityCampaign"
import { useIsOpportunitiesEnabled } from "./users"
import { ApiErrorResponse } from "./api"
import { urlParamsToOpportunityQueryObject } from "./opportunities"

const prefix = "/opportunity_campaigns"

interface FetchedOpportunityCampaign extends OpportunityCampaign {
  can_edit: boolean
}
export const useFetchOpportunityCampaigns = () => {
  const axiosInstance = useAuthAxios()
  const { data: isOpportunitiesEnabled } = useIsOpportunitiesEnabled()

  return useQuery(
    ["opportunityCampaigns"],
    async () => {
      const response = await (await axiosInstance).get<FetchedOpportunityCampaign[]>(prefix)

      return response.data
    },
    {
      enabled: Boolean(isOpportunitiesEnabled),
    },
  )
}

export const useFetchOpportunityCampaignsMap = () => {
  const fetchData = useFetchOpportunityCampaigns()
  if (fetchData.data) {
    return {
      ...fetchData,
      data: new Map(fetchData.data.map((opportunityCampaign) => [opportunityCampaign.id, opportunityCampaign])),
    }
  }
  return fetchData
}

export const useCreateOpportunityCampaigns = (onSuccess?: () => void, onError?: (errorMessage: string) => void) => {
  const axiosInstance = useAuthAxios()
  const queryClient = useQueryClient()

  return useMutation(
    async (params: { name: string; data: ReturnType<typeof urlParamsToOpportunityQueryObject> }) => {
      return (await axiosInstance).post(`${prefix}`, params)
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["opportunityCampaigns"])
        if (onSuccess) {
          onSuccess()
        }
      },
      onError: (error: AxiosError<ApiErrorResponse>) => {
        if (onError) {
          onError(error.response?.data?.errorMessage || "An unknown error occurred")
        }
      },
    },
  )
}

export const useUpdateOpportunityCampaigns = (
  id: unknown,
  onSuccess?: () => void,
  onError?: (errorMessage: string) => void,
) => {
  const axiosInstance = useAuthAxios()
  const queryClient = useQueryClient()

  return useMutation(
    async (params: { name: string; data: ReturnType<typeof urlParamsToOpportunityQueryObject> }) => {
      return (await axiosInstance).put(`${prefix}/${id}`, params)
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["opportunityCampaigns"])
        if (onSuccess) {
          onSuccess()
        }
      },
      onError: (error: AxiosError<ApiErrorResponse>) => {
        if (onError) {
          onError(error.response?.data?.errorMessage || "An unknown error occurred")
        }
      },
    },
  )
}
