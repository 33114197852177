import { FC, useMemo, useState } from "react"
import { FormControl, InputLabel, MenuItem, Select, TextField, Stack, Box } from "@mui/material"
import { DataTable } from "../../shared/DataTable"
import { useDeleteUser, useFetchUsers, useNewUser, useUpdateUser } from "../../../api/admin/users"
import Loading from "../../shared/Loading"
import { ActionsMenu } from "./ActionsMenu"
import { useFetchUserOrganizations } from "../../../api/admin/user_organizations"
import { columns } from "../../../tableColumns/adminUserColumns"
import { AdminUserContext } from "./context"

export const UserAdminContainer: FC = () => {
  const [userEmailFilter, setUserEmailFilter] = useState("")
  const [selectedUsers, setSelectedUsers] = useState<number[]>([])
  const [userOrgFilterInput, setUserOrgFilterInput] = useState()

  const { data: usersData } = useFetchUsers()
  const { data: userOrgsData } = useFetchUserOrganizations()
  const { mutate: newUser } = useNewUser()
  const { mutate: updateUser } = useUpdateUser()
  const { mutate: deleteUser } = useDeleteUser()

  const filteredUsers = useMemo(() => {
    if (usersData === undefined) {
      return []
    }

    const firstFilter =
      userOrgFilterInput === undefined
        ? usersData
        : usersData.filter((user: any) => {
            if (userOrgFilterInput === -1) {
              return user?.user_organization_id === null
            }
            return user?.user_organization_id === userOrgFilterInput
          })

    return firstFilter.filter((user: any) => user?.email.indexOf(userEmailFilter) !== -1)
  }, [usersData, userEmailFilter, userOrgFilterInput])

  if (usersData === undefined || userOrgsData === undefined) {
    return <Loading />
  }

  const onActionComplete = () => {
    setSelectedUsers([])
  }

  return (
    <AdminUserContext.Provider value={{ ids: selectedUsers, reset: () => setSelectedUsers([]) }}>
      <Stack direction="row" alignItems="center" spacing={1} marginY={2}>
        <ActionsMenu
          ids={selectedUsers}
          onActionComplete={onActionComplete}
          newRecord={newUser}
          updateRecord={updateUser}
          deleteRecord={deleteUser}
          recordName="user"
          records={usersData}
        />
        <TextField
          value={userEmailFilter}
          onChange={(event: any) => setUserEmailFilter(event.target.value)}
          label="Filter by Email"
          id="user-email-name-filter-input"
          fullWidth
        />
        <FormControl sx={{ width: "100ch" }}>
          <InputLabel id="user-org-select-label">User Organization</InputLabel>
          <Select
            value={userOrgFilterInput}
            onChange={(event: any) => setUserOrgFilterInput(event.target.value)}
            labelId="user-org-select-label"
            label="User Organization"
          >
            <MenuItem key={0} value={undefined}>
              All
            </MenuItem>
            <MenuItem key={-1} value={-1}>
              None
            </MenuItem>
            {userOrgsData.map((userOrg: any) => (
              <MenuItem key={userOrg.id} value={userOrg.id}>
                {userOrg.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Stack>
      <Box flex="1">
        <DataTable
          data={filteredUsers.map((user) => ({ ...user, roles: user.roles.map((role: any) => role.name).join(", ") }))}
          columns={columns}
          columnKey="id"
          selected={selectedUsers}
          setSelected={setSelectedUsers}
        />
      </Box>
    </AdminUserContext.Provider>
  )
}
